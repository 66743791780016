import * as d3 from "d3";
import * as timelib from "./beacon-timelib.d3.js";

const WIDTH = 2000;
const HEIGHT = 1000;
const offset = 40;

export default function beacon_graph(grs, { timefrom_ms = new Date().getTime(), timespan_ms = 60000, min = -2000, max = 2000, color = "#F00", data, name = "line" }) {
    //const selections = grs.append("svg").attr("id", name).append("path"); //.selectAll("g").select("path");

    const selections = grs;

    // convert the array with timestamp and value, to pixel coordinates.
    function getX(t) {
        return ((t - timefrom_ms) / timespan_ms) * WIDTH;
    }

    function getY(v) {
        return offset + ((HEIGHT - offset) / (max - min)) * (max - v);
    }

    //const selections = d3.select("#" + name).select("path");

    if (Array.isArray(data)) {
        const path_data = data.map((a) => [getX(a[0]), getY(a[1])]);
        if (path_data.length > 0) path_data.push([WIDTH, path_data[path_data.length - 1][1]]);
        selections.attr("d", d3.line().curve(d3.curveStepAfter)(path_data));
        //        selections.attr("d", d3.line().curve(d3.curveStepAfter)(path_data)).attr("fill", "none").attr("stroke", color).attr("stroke-width", 3);
        return;
    }

    if (typeof data === "number") {
        const Y = getY(data);
        const line_data = [
            [0, Y],
            [WIDTH, Y],
        ];
        selections.attr("d", d3.line().curve(d3.curveStepAfter)(line_data));
        //      selections.attr("d", d3.line().curve(d3.curveStepAfter)(line_data)).attr("fill", "none").attr("stroke", color).attr("stroke-width", 1);

        return;
    }
}
