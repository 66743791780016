<template>
    <div class="pa-16 bg-background-darken">
        <selector-component :structure="data.structure" @leftClickElement="click" />
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
import VuetifindDialog from "@/vuetifind-components/VuetifindDialog.vue";

const emit = defineEmits(["leftClickElement"]);

import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const data = reactive({
    structure: {},
});

const click = (element) => {
    router.push("/beacon/graph/" + element.macid);
};

onMounted(async () => {
    await $connection();

    $socket.removeListener("update-beacons");
    $socket.on("update-beacons", (o) => {
        Object.assign(data, o);
    });

    $socket.emit("update-beacons");
});
</script>
<script>
export default {
    path: "/beacons",
    name: "##&en Beacons ##&hu Jeladók ##",
    icon: "mdi-hvac",
    sort: 3100,
    root: "Home",
    section: "beacons",
};
</script>
