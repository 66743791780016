import * as d3 from "d3";

import * as timelib from "./timeline-timelib.d3.mjs";

export default function ({ timefrom, timespan, total_height, width, height, selector }) {
    const sections_data = timelib.timespanSections(timefrom, timespan, width);

    const selections = d3
        .select(selector)
        .selectAll("g")
        .data(sections_data, (d) => d.t + "@" + timespan);

    // the remover section
    selections.exit().remove();

    // the update section
    selections.attr("transform", (d) => "translate(" + d.x + ",0)");

    // the create section
    const timesections = selections
        .enter()
        .append("g")
        .attr("id", (d) => d.t + "@" + timespan)
        .attr("transform", (d) => "translate(" + d.x + ",0)");

    timesections
        .append("text")
        .attr("y", 1 + Math.round(width / 150))
        //.attr("x", -2)
        //.attr("x", "50%")
        //.attr("y", "80%")

        .text((d) => {
            if (d.label) return "|" + d.label;
        })
        .attr("class", "title-text")
        .attr("font-family", "Monospace")
        //.attr("alignment-baseline", "middle")

        .attr("font-size", Math.round(width / 150) + "px");
}
