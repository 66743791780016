<template>
    <v-container fluid class="pa-16">
        <v-toolbar>
            <v-btn icon size="large" @click.native="emitDocument('save')" :disabled="data.valid !== true">
                <v-icon>mdi-floppy</v-icon>
            </v-btn>
            <v-btn icon size="large" @click.native="emitDocument('call')" :disabled="data.valid !== true">
                <v-icon>mdi-play</v-icon>
            </v-btn>
            <v-toolbar-title>
                <pre>{{ data.database }} / {{ data.collection }} / {{ data.eventhandler.name || data._id }} </pre>
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon size="large" @click.native="emitDocument('trash')">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-toolbar>

        <vuetiform :format="data.format" v-model="data.eventhandler" v-model:valid="data.valid" :key="data.key" />
    </v-container>
    DOCUMENT
    <pre>{{ data.eventhandler }}</pre>
    FORMAT
    <pre>{{ data.format }}</pre>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import Vuetiform from "@/vuetiform/VuetiformPoliform.vue";

const router = useRouter();
const route = useRoute();

import { useEventhandlerStore } from "@/store/eventhandlerStore.mjs";
const store = useEventhandlerStore();

const data = reactive({
    database: route.params.database,
    collection: route.params.collection,
    _id: route.params.id,
    format: {},
    eventhandler: {},
    initialValues: {},
    key: 0,
});

const emitDocument = (act) => {
    $socket.emit(
        "eventhandler-document-" + act,
        {
            database: route.params.database,
            collection: route.params.collection,
            _id: route.params.id,
            eventhandler: data.eventhandler,
        },
        (r) => {
            if (r) if (r.uri) router.push(r.uri);
        }
    );
};

if (!$socket.hasListeners("update-eventhandler"))
    $socket.on("update-eventhandler", (o) => {
        data.key++;
        store.assign(o);
        Object.assign(data, o);
    });

const update = () => {
    $socket.emit("update-eventhandler", {
        database: route.params.database,
        collection: route.params.collection,
        _id: route.params.id,
    });
};

onMounted(async () => {
    //Ł("onMounted", route.params.id);
    await $connection();
    update();
});

watch(
    () => route.params,
    (newParams, oldParams) => {
        //console.log("Eventhandler Route parameters changed", newParams, oldParams);
        if (newParams.database && newParams.collection && newParams.id) update();
        else $socket.removeListener("update-eventhandler");
    }
);
</script>
<script>
export default {
    path: "/eventhandler/:database/:collection/:id",
    name: "eventhandler-editor-document",
    icon: "mdi-cog-box",
    sort: 110,
    root: "Home",
    onRenderTriggered(e) {
        debugger;
        // inspect which dependency is causing the component to re-render
    },
};
</script>
