import { defineStore } from "pinia";
import { reactive } from "vue";
export const useSessionStore = defineStore("session", () => {
    const session = reactive({});

    async function fetchSession(resolve, reject) {
        const response = await fetch("/session.json", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            if (reject) reject();
            return console.log("[sessionStore] There was an error loading the session. ", response);
        }
        const data = await response.json();

        Object.assign(session, data);

        if (resolve) resolve();
        return data;
    }

    const initialization = new Promise((resolve, reject) => {
        fetchSession(resolve, reject);
    });

    async function resetSession() {
        for (const i in session) delete session[i];
        fetchSession();
    }

    return { fetchSession, initialization, resetSession, session };
});
