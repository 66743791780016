import { createRouter, createWebHistory } from "vue-router";
import components from "../vue-components.mjs";

function sortByMeta(a, b) {
    if (a.meta.sort > b.meta.sort) return 1;
    if (a.meta.sort < b.meta.sort) return -1;
    return 0;
}

function getComponentRoute(filename) {
    const component = components[filename];
    if (typeof component.path === "string" || typeof component.link === "string") {
        const route = { meta: {} };
        route.component = component;
        route.path = component.path || "/" + filename.toLowerCase();
        route.meta.name = component.name || filename;
        route.meta.link = component.link || component.path || "/" + filename.toLowerCase();
        route.meta.icon = component.icon || "mdi-navigation-variant-outline";
        route.meta.sort = component.sort || 1000;
        route.meta.filename = filename;
        route.meta.root = component.root || "root";
        route.meta.section = component.section || null;
        return route;
    }
    return null;
}

export default function ({ app }) {
    const allroutes = new Map();
    const parents = new Set();

    Object.keys(components).forEach((filename) => {
        const route = getComponentRoute(filename);
        if (!route) return;
        allroutes.set(filename, route);
        parents.add(route.meta.root);
    });

    function getChildren(from) {
        const children = new Map();

        allroutes.forEach((route, filename) => {
            if (route.meta.root !== from) return;
            // children are identified by filename
            const arr = getChildren(filename);
            if (arr.length > 0) route.children = arr;
            children.set(filename, route);
        });

        return Array.from(children.values()).sort(sortByMeta);
    }

    const routes = getChildren("root");

    console.log("[vue/router] " + routes.map((e) => e.path + " " + e.meta.filename).join(" | "));

    const router = createRouter({
        history: createWebHistory(),
        routes,
    });

    app.use(router);
}
