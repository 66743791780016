<template>
    <div v-if="props.count > props.itemsPerPage">
        <v-btn
            class="bg-primary ma-2"
            flat
            icon="mdi-skip-backward"
            @click="
                data.page = 1;
                update();
            "
            :disabled="data.page <= 1"
        ></v-btn>
        <v-btn class="bg-primary ma-2" flat icon="mdi-step-backward-2" @click="update(-10)" :disabled="data.page - 10 < 1"></v-btn>
        <v-btn class="bg-primary ma-2" flat icon="mdi-step-backward" @click="update(-1)" :disabled="data.page <= 1"></v-btn>
        {{ data.page }} / {{ pages() }}
        <v-btn class="bg-primary ma-2" flat icon="mdi-step-forward" @click="update(+1)" :disabled="data.page + 1 > pages()"> </v-btn>
        <v-btn class="bg-primary ma-2" flat icon="mdi-step-forward-2" @click="update(+10)" :disabled="data.page + 10 > pages()"> </v-btn>
        <v-btn
            class="bg-primary ma-2"
            flat
            icon="mdi-skip-forward"
            @click="
                data.page = pages();
                update();
            "
            :disabled="data.page >= pages()"
        >
        </v-btn>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

const props = defineProps(["page", "count", "itemsPerPage"]);
const emit = defineEmits(["update"]);

const pages = () => {
    return Math.ceil(props.count / props.itemsPerPage);
};
const data = reactive({
    page: props.page,
});

const update = (a) => {
    if (a) {
        if (a > 0) if (data.page + a <= pages()) data.page += a;
        if (a < 0) if (data.page + a >= 1) data.page += a;
    }
    emit("update", data.page);
};
</script>
