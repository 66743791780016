const Ł = console.log;

import * as d3 from "d3";

import * as timelib from "./timeline-timelib.d3.mjs";
import timehead_background from "./timehead-background.d3.mjs";

import timeline_background from "./timeline-background.d3.mjs";
import timeline_backrows from "./timeline-backrows.d3.mjs";
import timeline_content from "./timeline-content.d3.mjs";
import timeline_cursor from "./timeline-cursor.d3.mjs";
import timeline_center from "./timeline-center.d3.mjs";

//const document_width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
//const document_height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;

// around 2000
const DEFAULT_WIDTH = 2000; //document_width;
// minimum 100
const DEFAULT_HEIGHT = 100;

// that is this/data of the local vue component, or better said it's properties
// store is based on the server updates that came with socketio to vuex/pinia
// updaters are the functions like the one that needs to run when new data must be sent to the server
export default function timeline_d3(that, store, updater) {
    if (!that) that = {};
    const dataset = store.dataset || [];
    if (!updater) updater = () => {};

    if (!that.width) that.width = DEFAULT_WIDTH;
    if (!that.height) that.height = DEFAULT_HEIGHT;
    if (!that.timespan) that.timespan = 3600;
    if (!that.timefrom) that.timefrom = Math.floor(new Date().getTime() / 1000 - that.timespan / 2);
    if (that.playing === undefined) that.playing = false;
    if (that.dragging === undefined) that.dragging = true;

    let grabx = 0;
    let basetime = 0;
    let dragging = false;

    // drag functions in this context are for the time-axis.
    const dragstarted = (event, d) => {
        if (event.sourceEvent.target.classList.contains("clickable")) return event.sourceEvent.preventDefault();
        if (that.playing) return;
        dragging = true;
        d3.select(this).style("cursor", "grab");
        grabx = event.x;
        basetime = that.timefrom;
    };

    const dragged = (event) => {
        if (event.sourceEvent.target.classList.contains("clickable")) return event.sourceEvent.preventDefault();
        if (that.playing) return;
        const offset = -grabx + event.x;
        d3.select("#timeline-background").attr("x", offset);
        d3.select("#timeline-content").attr("x", offset);
        d3.select("#timeline-cursor").attr("x", offset);
        d3.select("#timehead-background").attr("x", offset);

        that.timefrom = basetime - (-grabx + event.x) / (that.width / that.timespan);
    };

    const dragended = (event, d) => {
        if (event.sourceEvent.target.classList.contains("clickable")) return event.sourceEvent.preventDefault();
        if (that.playing) return;
        dragging = false;
        d3.select(this).style("cursor", "default");
        updater.render();
    };
    // .. drag of timescale end.

    const render_timeline = () => {
        const total_height = 10 + dataset.length * that.height;
        const timeline_d3 = d3.select("#timeline-d3");

        timeline_d3.selectAll("svg").remove();
        const svg = timeline_d3.append("svg");

        svg.attr("viewBox", "0 0 " + that.width + " " + total_height).style("cursor", "default");

        svg.append("svg").attr("id", "timeline-background");
        svg.append("svg").attr("id", "timeline-backrows").attr("y", 10);
        svg.append("svg").attr("id", "timeline-content").attr("y", 10);
        svg.append("svg").attr("id", "timeline-cursor").append("line");

        svg.append("svg").attr("id", "timeline-center").append("line");

        timeline_background({ ...that, selector: "#timeline-background" });
        timeline_backrows(that, dataset);

        timeline_content(that, dataset, updater);

        svg.call(d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended));

        var leadinGradient = svg
            .append("defs")
            .append("linearGradient")
            .attr("id", "leadinGradient")
            .attr("x1", "0%")
            .attr("y1", "0%")
            .attr("x2", "0%")
            .attr("y2", "100%")
            .attr("gradientTransform", "rotate(-90)");
        leadinGradient.append("stop").attr("offset", "0%").attr("stop-color", "black").attr("stop-opacity", 1);
        leadinGradient.append("stop").attr("offset", "30%").attr("stop-color", "white").attr("stop-opacity", 1);
        leadinGradient.append("stop").attr("offset", "100%").attr("stop-color", "white").attr("stop-opacity", 0);

        var leadoutGradient = svg
            .append("defs")
            .append("linearGradient")
            .attr("id", "leadoutGradient")
            .attr("x1", "0%")
            .attr("y1", "0%")
            .attr("x2", "0%")
            .attr("y2", "100%")
            .attr("gradientTransform", "rotate(-90)");
        leadoutGradient.append("stop").attr("offset", "0%").attr("stop-color", "white").attr("stop-opacity", 0);
        leadoutGradient.append("stop").attr("offset", "70%").attr("stop-color", "white").attr("stop-opacity", 1);
        leadoutGradient.append("stop").attr("offset", "100%").attr("stop-color", "black").attr("stop-opacity", 1);
    };

    const render_timehead = () => {
        const total_height = 20;

        const timehead_d3 = d3.select("#timehead-d3");
        timehead_d3.selectAll("svg").remove();
        const svg = timehead_d3.append("svg");

        svg.attr("viewBox", "0 0 " + that.width + " " + 2 * total_height).style("cursor", "default");

        svg.append("svg").attr("id", "timehead-background");

        timehead_background({ ...that, selector: "#timehead-background" });

        svg.call(d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended));
    };

    const clear = () => {
        const timeline_d3 = d3.select("#timeline-d3");
        timeline_d3.selectAll("svg").remove();
    };

    const render = () => {
        if (dragging) return;
        const timeline_d3 = d3.select("#timeline-d3");
        timeline_d3.selectAll("svg").remove();

        that.total_height = 100 + dataset.length * that.height;

        render_timeline();
        render_timehead();
        timeline_center(that);
        timeline_cursor(that);
    };

    const timer = () => {
        if (that.playing) {
            that.timefrom = Math.round(new Date().getTime() / 1000 - that.timespan / 2);
            render();
        }
        if (!dragging) timeline_cursor(that);
    };

    updater.render = render;

    render();

    return {
        render,
        clear,
        timer,
    };
}
