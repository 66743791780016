<template>
    <v-container fluid class="pa-10">
        <v-row no-gutters>
            <v-col cols="1" style="max-width: 50px; padding-top: 20px">
                <v-card width="30" height="1050" style="padding-top: 10px">
                    <v-range-slider style="padding-top: 10px; min-height: 2430px !important" v-model="data.y" min="0" :max="HEIGHT" direction="vertical" class="large-slider" />
                </v-card>
            </v-col>
            <v-col cols="11">
                <beacon-graph-component :macid="route.params.macid" :data="data" />
                <v-card :width="WIDTH">
                    <v-range-slider v-model="data.x" min="0" :max="WIDTH" />
                    <v-toolbar density="compact">
                        <v-btn icon @click="openBeaconDocument()">
                            <v-icon>mdi-hvac</v-icon>
                        </v-btn>
                        {{ route.params.macid }}
                        <v-btn icon @click="addCalibration(true)">
                            <v-icon>mdi-plus-box</v-icon>
                        </v-btn>
                        <v-btn icon @click="addCalibration(false)">
                            <v-icon>mdi-plus-box-outline</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="tooglePlay()">
                            <v-icon v-if="data.play">mdi-stop</v-icon>
                            <v-icon v-if="!data.play">mdi-play</v-icon>
                        </v-btn>
                        <v-btn icon @click="modifyTimespan(1)" :disabled="data.timespan_ms > 500000">
                            <v-icon>mdi-magnify-minus</v-icon>
                        </v-btn>
                        <v-btn icon @click="modifyTimespan(-1)" :disabled="data.timespan_ms < 10000">
                            <v-icon>mdi-magnify-plus</v-icon>
                        </v-btn>
                        <v-btn icon @click="data.time -= data.timespan_ms / 10" :disabled="data.play">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn icon @click="data.time += data.timespan_ms / 10" :disabled="data.play">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="info()">
                            <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-actions>
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-select dense label="Group" :items="groups" v-model="data.group" @update:modelValue="changeGroup()"></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select dense label="Graphs" multiple :items="getGraphs(data.group)" v-model="data.graphs"></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

// asymlink to the allocated file
import SENSOR_PROPERTIES from "@/1000.SENSOR_PROPERTIES.mjs";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
import VuetifindDialog from "@/vuetifind-components/VuetifindDialog.vue";
import BeaconGraphComponent from "@/beacon-components/D3Graph.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const WIDTH = 2000;
const HEIGHT = 1000;

const groups = [...new Set(Object.values(SENSOR_PROPERTIES).map((e) => e.group))];

const getGraphs = (g) => {
    return Object.keys(SENSOR_PROPERTIES).filter((e) => SENSOR_PROPERTIES[e].group === g);
};

const changeGroup = () => {
    data.graphs.length = 0;
    Object.assign(data.graphs, getGraphs(data.group));
    const proto = SENSOR_PROPERTIES[getGraphs(data.group)[0]];
    data.min = proto.min;
    data.max = proto.max;
};
const data = reactive({
    x: [0, WIDTH],
    y: [0, HEIGHT],
    group: groups[0],
    graphs: getGraphs(groups[0]),
    play: false,
    time: new Date().getTime(),
    timespan_ms: 60000,
    min: -2000,
    max: 2000,
});

const tooglePlay = () => {
    data.play = !data.play;
    if (!data.play) data.time = new Date().getTime();
};

const modifyTimespan = (f) => {
    if (f > 0) if (data.timespan_ms < 500000) data.timespan_ms = data.timespan_ms * 1.25;
    if (f < 0) if (data.timespan_ms > 10000) data.timespan_ms = data.timespan_ms / 1.25;
};

const info = () => {
    router.push("/beacon/info/" + route.params.macid);
};

const addCalibration = (b) => {
    const keys = data.graphs;
    const max = Math.floor(data.min + (Math.max(data.y[0], data.y[1]) / HEIGHT) * (data.max - data.min));
    const min = Math.floor(data.min + (Math.min(data.y[0], data.y[1]) / HEIGHT) * (data.max - data.min));
    const timespan = Math.floor(((Math.abs(data.x[0] - data.x[1]) / WIDTH) * data.timespan_ms) / 1000);

    $socket.emit("add-calibration", { keys, max, min, timespan, macid: route.params.macid }, (uri) => {
        if (b) if (uri) router.push(uri);
    });
};
const openBeaconDocument = () => {
    $socket.emit("open-beacon-document", { macid: route.params.macid }, (uri) => {
        if (uri) router.push(uri);
    });
};

onMounted(async () => {
    await $connection();
    $socket.emit("client-time-check", new Date().getTime());
});

import background from "@/beacon-background.d3";
import graph from "@/beacon-graph.d3";
</script>
<script>
export default {
    path: "/beacon/graph/:macid",
    name: "beacon-graph",
    icon: "mdi-hvac",
    sort: 100,
    root: "Home",
};
</script>
<style scoped></style>
