<template>
    <div v-for="c in sidebar_components">
        <component :is="c" />
    </div>
</template>

<script setup>
import componentlist from "../../sidebar-components.mjs";
const sidebar_components = Object.keys(componentlist).map((e) => componentlist[e]);
</script>
