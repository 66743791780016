<template>
    <div :class="props.classes || 'text-h7 pa-2 ml-2'">
        <v-select
            v-model="data.value"
            :label="props.label"
            :items="getItems()"
            @input="updateHandler()"
            @update:modelValue="updateHandler()"
            :prepend-icon="props.icon || 'mdi-currency-usd'"
            append-icon="mdi-arrow-down-drop-circle"
        />
    </div>
</template>
<script setup>
import { isProxy, toRaw } from "vue";
import { ref, reactive, nextTick } from "vue";
import { structuredClone as clone } from "../../helper-functions.mjs";
import { getCurrentInstance } from "vue";

import { useEventhandlerStore } from "@/store/eventhandlerStore.mjs";
const store = useEventhandlerStore();

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}

const props = defineProps(["label", "classes", "modelValue", "items", "icon", "filters"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ value: clone(props.modelValue || null) });

// return the array index in the eventhandler sequence
const getIndex = () => {
    let instance = getCurrentInstance();
    while (instance) {
        const raw = toRaw(instance.props);
        if (raw.identifier === "eventhandler-sequence" && raw.index !== undefined) return raw.index;
        instance = instance.parent;
    }
};

const getItems = () => {
    const index = getIndex();
    const arra = store.eventhandler.sequence;
    const arr = arra.slice(0, index);
    const list = arr.map((e) => e.contextkeys || []).flat(1);
    const items = props.items || [];
    const unfiltered = [...items, ...list];
    if (!props.filters) return unfiltered;
    const filters = props.filters.split("|");
    const filtered = unfiltered.filter((e) => filters.includes(e._type));
    return filtered;
};

const isValid = () => {
    const arr = getItems();
    const a = arr.map((e) => e.value || e);
    return a.includes(data.value);
};

const updateHandler = async (from) => {
    await nextTick();
    const datum = clone(data.value);
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};
</script>

<script>
export default {
    inheritAttrs: false,
    name: "eventhandler-contextkey",
};
</script>
