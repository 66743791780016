const getNextLang = () => {
    const keys = Object.keys(ß.APP_LANGUAGES);
    const lang_index = 1 + keys.indexOf(ß.LANG);
    const next_lang = keys[lang_index % keys.length];
    return next_lang;
};

const language_name = () => {
    return ß.APP_LANGUAGES[getNextLang()];
};

const language_selection = async () => {
    const response = await fetch("/post-lang.json", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ lang: getNextLang() }),
    });

    if (!response.ok) return console.log("[multilanguage] There was an error setting the language. ", response);

    const data = await response.json();
    if (data === "OK") location.reload();
};

export { language_selection, language_name };
