<template>
    <v-toolbar density="compact">
        <v-btn size="x-large">
            <v-icon dark>mdi-play-circle</v-icon>
        </v-btn>
    </v-toolbar>
    <v-card v-resize="onResize" id="timeline" :height="resizedHeight" :width="resizedWidth" class="scroll gray">
        <svg width="400" height="300" viewBox="0 0 400 300" id="control-panel-content"></svg>
    </v-card>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch } from "vue";
// ...

const dialog = ref(true);

const resizedHeight = ref(0);
const resizedWidth = ref(0);
const data = [
    { type: "circle", cx: 140, cy: 140, r: 50, fill: "aliceblue" },
    { type: "rect", X: 30, y: 200, width: 200, height: 100, fill: "lightblue" },
];

let rect;
onMounted(async () => {
    await $connection();

    // Using font size of 16px.
    // Using font-family: "Courier New", Courier, monospace;

    const fontWidth = 9.61; // px
    const fontHeight = 18; // px
    const svgControlArea = document.getElementById("control-panel-content");

    const buttonsData = [
        {
            buttonText: "eci-peci",
            top: 5,
            left: 5,
            right: 107,
            transform: "translate(5px, 5px)",
        },
        {
            buttonText: "valami",
        },
        {
            buttonText: "k",
        },
        {
            buttonText: "Hosszú szöveg",
        },
        {
            buttonText: "másik valami",
        },
        {
            buttonText: "másik hosszú valami",
        },
        {
            buttonText: "xxx",
        },
        {
            buttonText: "XD XSD",
        },
        {
            buttonText: "másik valami valami másik",
        },
        {
            buttonText: "randomized text",
        },
        {
            buttonText: "textcontent for the button",
        },
    ];

    /*
    for (let i = 1; i < buttonsData.length; i++) {
        buttonsData[i].offset = buttonsData[i - 1].offset + buttonsData[i - 1].buttonText.length * fontWidth + 30;
        buttonsData[i].transform = `translate(${buttonsData[i].offset}px, 5px)`;
    }
	*/

    for (let i = 1; i < buttonsData.length; i++) {
        const prevButton = buttonsData[i - 1];
        const currentButton = buttonsData[i];
        const prevButtonWidth = prevButton.buttonText.length * fontWidth + 30;
        currentButton.left = prevButton.left + prevButtonWidth;
        const newCurrentRight = prevButton.right + currentButton.buttonText.length * fontWidth + 30;
        if (newCurrentRight >= svgControlArea.getAttribute("width")) {
            currentButton.left = 5;
            currentButton.top = prevButton.top + 40;
            currentButton.right = currentButton.buttonText.length * fontWidth + 30;
        } else {
            currentButton.top = prevButton.top;
            currentButton.right = newCurrentRight;
        }
        currentButton.transform = `translate(${currentButton.left}px, ${currentButton.top}px)`;
    }
    console.log(buttonsData);

    const createButton = ({ eventHandler = (e) => console.log(e.target.previousElementSibling.textContent + " buttons' default click handler called.") } = {}) => {
        d3.select("#control-panel-content")
            .selectAll("g")
            .data(buttonsData)
            .enter()
            .append("g")
            .each((data, index, selection) => {
                console.log(data, index, selection);
                const currentButton = d3
                    .select(selection[index])
                    .attr("class", "svg-button") // classed ("class-name") is not actepted here ...
                    .attr("style", "transform: " + data.transform)
                    .on("click", data.eventHandler);

                currentButton
                    .append("rect")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("rx", 5)
                    .attr("width", data.buttonText.length * fontWidth + 20)
                    .attr("height", fontHeight + 20)
                    .attr("fill", "#0000001e");

                currentButton.append("text").text(data.buttonText).attr("x", 10).attr("y", 23);

                currentButton
                    .append("rect")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("rx", 5)
                    .attr("width", data.buttonText.length * fontWidth + 20)
                    .attr("height", fontHeight + 20)
                    .attr("fill", "#0080001e")
                    .attr("fill-opcity", 0)
                    .classed("overlay");

                // currentButton.node ().addEventListener ('mousedown', e => {		// Put a separate "mousedown" event listener to the current <g> / button.
                //     const { x, y } = e.currentTarget.getBoundingClientRect ();  // d3 would overwrite the handler function which is defined as a
                //     const draggedX = e.clientX - x;								// "mousedown" property.
                //     const draggedY = e.clientY - y;
                //     e.currentTarget.draggedAtX = draggedX;						// Calculate the x
                //     e.currentTarget.draggedAtY = draggedY;						// and y coordinate of mouse pinter relative to the dragged element's top-left corner.
                // });																// Then attach these numbers as properties to the dragged element avoiding to create
            }); // global variables to follow these values.

        let drag = d3.drag().on("drag", handleDrag);

        function handleDrag(e) {
            // const x = e.sourceEvent.target.parentElement.draggedAtX;			// Read the dragging element-relative x coordinate from the element.
            // const y = e.sourceEvent.target.parentElement.draggedAtY;			// Read the dragging element-relative y coordinate from the element.
            // if ((! x) || (! y)) return false;									// Do not do anything if the mouse cursor is out of the area where
            e.subject.transform = `translate(${e.x}px, ${e.y}px)`; // drag and drop events are listened.
            dialog.value = true;
            update();
        }

        function initDrag() {
            d3.select("svg").selectAll("g").call(drag);
        }

        function update() {
            d3.select("svg")
                .selectAll("g")
                .data(buttonsData)
                .join("g")
                .attr("style", (d) => "transform:" + d.transform);
        }

        update();
        initDrag();
    };

    createButton();
});

function onSVGResize() {
    console.log("SVG Resized");
    console.log(this);
}

const onResize = () => {
    resizedHeight.value = window.innerHeight - 150;
    resizedWidth.value = window.innerWidth;
};
</script>
<script>
import * as d3 from "d3";
export default {
    path: "/gui/:database/controlpanel/:id",
    name: "controlpanel-document",
    icon: "mdi-monitor-dashboard",
    sort: 110,
    root: "Home",
};
</script>
<style scoped>
.scroll {
    overflow-y: scroll;
}
.gray {
    background-color: lightgray;
}
#control-panel-container {
    width: 70%;
    background-color: aliceblue;
}
#control-panel-content {
    /*width: 100%;*/
    font-weight: 900;
    font-family: "Courier New";
    background-color: beige;
}

#control-panel-content > g {
    cursor: pointer;
}

#control-panel-content > g > .overlay:hover {
    fill-opacity: 1;
}
#control-panel-content > g > .overlay:active {
    fill: #0000801e;
}
/*
.svg-button {
    cursor: pointer;
}

.svg-button .overlay:hover {
    fill-opacity: 1;
}
.svg-button .overlay:active {
    fill: #0000801e;
}
*/
</style>
