<template>
    <div class="pa-10">
        <svg></svg>
    </div>
</template>

<script>
import * as d3 from "d3";
export default {
    path: "/hello-d3",
    name: "hello-d3",
    icon: "mdi-chart-pie",
    sort: 100,
    root: "Home",
    data() {
        return {};
    },
    mounted() {
        const width = 400;
        const height = 400;
        const data = [];

        const svg = d3.select("svg").attr("width", width).attr("height", height);
        const g = svg
            .append("rect")
            .attr("height", (g) => 100)
            .attr("width", 100);
    },
};
</script>
