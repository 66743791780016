// /srv/codepad-project/@gui-modules/controlpanels/vue3/Controlpanel.vue
import Controlpanel from './src/Controlpanel.vue';

// /srv/codepad-project/@neverland-theme-modules/asylum/vue3/KioskAsylum.vue
import KioskAsylum from './src/KioskAsylum.vue';

// /srv/codepad-project/@neverland-theme-modules/asylum/vue3/OperatorAsylum.vue
import OperatorAsylum from './src/OperatorAsylum.vue';

// /srv/codepad-project/@sensor-modules/beacons/vue3/BeaconGraph.vue
import BeaconGraph from './src/BeaconGraph.vue';

// /srv/codepad-project/@sensor-modules/beacons/vue3/BeaconInfo.vue
import BeaconInfo from './src/BeaconInfo.vue';

// /srv/codepad-project/@sensor-modules/beacons/vue3/Beacons.vue
import Beacons from './src/Beacons.vue';

// /srv/codepad-project/@vue3-modules/app/vue3/App.vue
import App from './src/App.vue';

// /srv/codepad-project/@gui-modules/gui/vue3/GuiSelection.vue
import GuiSelection from './src/GuiSelection.vue';

// /srv/codepad-project/@neverland-theme-modules/aztec/vue3/KioskAztec.vue
import KioskAztec from './src/KioskAztec.vue';

// /srv/codepad-project/@neverland-theme-modules/aztec/vue3/OperatorAztec.vue
import OperatorAztec from './src/OperatorAztec.vue';

// /srv/codepad-project/@vue3-modules/checkpoints/vue3/Checkpoints.vue
import Checkpoints from './src/Checkpoints.vue';

// /srv/codepad-project/@gui-modules/timeline/vue3/Timeline.vue
import Timeline from './src/Timeline.vue';

// /srv/codepad-project/@gui-modules/timeline/vue3/TimelineEditor.vue
import TimelineEditor from './src/TimelineEditor.vue';

// /srv/codepad-project/@neverland-modules/game/vue3/Kiosk.vue
import Kiosk from './src/Kiosk.vue';

// /srv/codepad-project/@neverland-modules/game/vue3/Operator.vue
import Operator from './src/Operator.vue';

// /srv/codepad-project/@neverland-modules/game/vue3/OperatorClock.vue
import OperatorClock from './src/OperatorClock.vue';

// /srv/codepad-project/@neverland-modules/game/vue3/OperatorGame.vue
import OperatorGame from './src/OperatorGame.vue';

// /srv/codepad-project/@neverland-modules/game/vue3/OperatorGameManager.vue
import OperatorGameManager from './src/OperatorGameManager.vue';

// /srv/codepad-project/@neverland-modules/game/vue3/OperatorHistory.vue
import OperatorHistory from './src/OperatorHistory.vue';

// /srv/codepad-project/@neverland-theme-modules/chernobyl/vue3/KioskChernobyl.vue
import KioskChernobyl from './src/KioskChernobyl.vue';

// /srv/codepad-project/@neverland-theme-modules/chernobyl/vue3/OperatorChernobyl.vue
import OperatorChernobyl from './src/OperatorChernobyl.vue';

// /srv/codepad-project/@eventhandler-modules/eventhandlereditor/vue3/EventhandlerEditorDatabase.vue
import EventhandlerEditorDatabase from './src/EventhandlerEditorDatabase.vue';

// /srv/codepad-project/@eventhandler-modules/eventhandlereditor/vue3/EventhandlerEditorDocument.vue
import EventhandlerEditorDocument from './src/EventhandlerEditorDocument.vue';

// /srv/codepad-project/@neverland-theme-modules/jailbreak/vue3/KioskJailbreak.vue
import KioskJailbreak from './src/KioskJailbreak.vue';

// /srv/codepad-project/@neverland-theme-modules/jailbreak/vue3/OperatorJailbreak.vue
import OperatorJailbreak from './src/OperatorJailbreak.vue';

// /srv/codepad-project/@vue3-modules/d3/vue3/HelloD3.vue
import HelloD3 from './src/HelloD3.vue';

// /srv/codepad-project/@neverland-theme-modules/moneyheist/vue3/KioskMoneyHeist.vue
import KioskMoneyHeist from './src/KioskMoneyHeist.vue';

// /srv/codepad-project/@neverland-theme-modules/moneyheist/vue3/OperatorMoneyheist.vue
import OperatorMoneyheist from './src/OperatorMoneyheist.vue';

// /srv/codepad-project/@vue3-modules/home/vue3/Home.vue
import Home from './src/Home.vue';

// /srv/codepad-project/@neverland-theme-modules/serialkiller/vue3/KioskSerialkiller.vue
import KioskSerialkiller from './src/KioskSerialkiller.vue';

// /srv/codepad-project/@neverland-theme-modules/serialkiller/vue3/OperatorSerialkiller.vue
import OperatorSerialkiller from './src/OperatorSerialkiller.vue';

// /srv/codepad-project/@users-modules/login/vue3/Account.vue
import Account from './src/Account.vue';

// /srv/codepad-project/@users-modules/login/vue3/LoginCard.vue
import LoginCard from './src/LoginCard.vue';

// /srv/codepad-project/@users-modules/login/vue3/LoginDialog.vue
import LoginDialog from './src/LoginDialog.vue';

// /srv/codepad-project/@neverland-theme-modules/wildwest/vue3/KioskWildwest.vue
import KioskWildwest from './src/KioskWildwest.vue';

// /srv/codepad-project/@neverland-theme-modules/wildwest/vue3/OperatorWildwest.vue
import OperatorWildwest from './src/OperatorWildwest.vue';

// /srv/codepad-project/@vue3-modules/onlineusers/vue3/OnlineUsers.vue
import OnlineUsers from './src/OnlineUsers.vue';

// /srv/codepad-project/@neverland-theme-modules/wizards/vue3/KioskWizards.vue
import KioskWizards from './src/KioskWizards.vue';

// /srv/codepad-project/@neverland-theme-modules/wizards/vue3/OperatorWizards.vue
import OperatorWizards from './src/OperatorWizards.vue';

// /srv/codepad-project/@users-modules/profile/vue3/Profile.vue
import Profile from './src/Profile.vue';

// /srv/codepad-project/@database-modules/dataview/vue3/Collection.vue
import Collection from './src/Collection.vue';

// /srv/codepad-project/@database-modules/dataview/vue3/Database.vue
import Database from './src/Database.vue';

// /srv/codepad-project/@database-modules/dataview/vue3/Datapoints.vue
import Datapoints from './src/Datapoints.vue';

// /srv/codepad-project/@database-modules/dataview/vue3/Document.vue
import Document from './src/Document.vue';

// /srv/codepad-project/@neverland-modules/neverland/vue3/DesignGame.vue
import DesignGame from './src/DesignGame.vue';

// /srv/codepad-project/@neverland-modules/supervisor/vue3/Supervisor.vue
import Supervisor from './src/Supervisor.vue';

// /srv/codepad-project/@database-modules/queries/vue3/QueriesEditorDatabase.vue
import QueriesEditorDatabase from './src/QueriesEditorDatabase.vue';

// /srv/codepad-project/@database-modules/queries/vue3/QueriesEditorDocument.vue
import QueriesEditorDocument from './src/QueriesEditorDocument.vue';

// /srv/codepad-project/@database-modules/queries/vue3/Query.vue
import Query from './src/Query.vue';

export default { Controlpanel, KioskAsylum, OperatorAsylum, BeaconGraph, BeaconInfo, Beacons, App, GuiSelection, KioskAztec, OperatorAztec, Checkpoints, Timeline, TimelineEditor, Kiosk, Operator, OperatorClock, OperatorGame, OperatorGameManager, OperatorHistory, KioskChernobyl, OperatorChernobyl, EventhandlerEditorDatabase, EventhandlerEditorDocument, KioskJailbreak, OperatorJailbreak, HelloD3, KioskMoneyHeist, OperatorMoneyheist, Home, KioskSerialkiller, OperatorSerialkiller, Account, LoginCard, LoginDialog, KioskWildwest, OperatorWildwest, OnlineUsers, KioskWizards, OperatorWizards, Profile, Collection, Database, Datapoints, Document, DesignGame, Supervisor, QueriesEditorDatabase, QueriesEditorDocument, Query };