<template>
    <v-container class="pa-10">
        <v-btn @click="graph()"> ##&en Graph ##&hu Gráf ## </v-btn>
        <v-card max-width="2000">
            <v-table fixed-header density="compact">
                <thead>
                    <tr>
                        <th class="text-left">Key</th>
                        <th class="text-left">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v, k, i) in latestdata" :key="i">
                        <td :style="{ width: '300px' }">{{ k }}</td>
                        <td>{{ v }}</td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>
    </v-container>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { io } from "socket.io-client";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const latestdata = reactive({});
const data = reactive({});
const macid = route.params.macid;

const graph = () => {
    router.push("/beacon/graph/" + macid);
};

onMounted(async () => {
    await $connection();

    const options = ß.SOCKETIO_OPTIONS || {};

    const connect = "https://" + ß.HOSTNAME + ":" + ß.SERVER_PORT + "/beacon-info";
    console.log("[socket.io] " + connect, options);

    options.withCredentials = true;
    options.forceNew = true;

    const sensor_socket = io(connect, options);
    sensor_socket.connect();

    while (!sensor_socket.connected) await new Promise((resolve) => setTimeout(resolve, 100));

    sensor_socket.emit("init-info", macid, (o) => {
        Object.assign(latestdata, o.latestdata);
    });

    sensor_socket.on("addto-info", (e) => {
        Object.assign(latestdata, e);
    });
});
</script>
<script>
export default {
    path: "/beacon/info/:macid",
    name: "beacon-info",
    icon: "mdi-hvac",
    sort: 100,
    root: "Home",
};
</script>
<style scoped></style>
