<template>
    <v-responsive max-width="250" class="pr-4">
        <v-text-field
            v-model="data.search"
            density="compact"
            flat
            hide-details
            rounded="pill"
            variant="solo-filled"
            append-inner-icon="mdi-magnify"
            @click:appendInner="search()"
            @keyup.enter="search()"
            :rules="[validateSearch]"
        ></v-text-field>
    </v-responsive>
    <v-dialog v-model="data.dialog" fullscreen scrollable :scrim="false" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar color="primary">
                <v-toolbar-title>{{ data.searchterm }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="data.dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <v-container fluid class="py-8 px-6">
                    <v-card v-for="(hits, k) in data.results" :title="k">
                        <v-card v-for="hit in hits" :title="hit.title" :subtitle="hit.subtitle" :text="hit.text" class="pl-10" @click="open(hit)" />
                    </v-card>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const data = reactive({
    search: null,
    dialog: false,
    searchterm: null,
    results: {},
});

const search = () => {
    if (!data.search) return;
    if (data.search.length < 3) return (data.search = null);
    data.searchterm = data.search;
    data.search = null;
    data.results = {};
    data.dialog = true;
    $socket.emit("search-searchterm", { searchterm: data.searchterm }, () => {
        data.searchterm = "";
    });
};

const open = (hit) => {
    data.searchterm = "";
    data.search = null;
    data.results = {};
    data.dialog = false;
    router.push(hit.link);
};

const validateSearch = (e) => {
    if (!e) return true;
    if (e.length < 3) return false;
    return true;
};
onMounted(async () => {
    await $socket.connection;

    $socket.on("search-results", ({ searchterm, results }) => {
        if (data.searchterm !== searchterm) return;
        Object.assign(data.results, results);
    });
});
</script>
