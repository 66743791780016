<template>
    <div class="non-selectable">
        <!-- absolute layouts -->

        <!-- mintha ez a fade nem menne -->
        <div v-if="data.video" id="videoContainer">
            <transition name="fade" mode="in-out">
                <video id="video" :src="data.video" width="1080" height="1920" muted autoplay></video>
            </transition>
        </div>

        <!--<img id="backgroundPicture" src="@/asylum-media/background.jpg" height="1200" />-->
        <!--<img id="backgroundPicture" src="https://as1.ftcdn.net/v2/jpg/01/16/78/92/1000_F_116789297_jjwaI3yAms5F8H18BPanaqtYwd3Y2zzb.jpg" height="1200" />-->
      	<img
          id="backgroundPicture"
          src="@/asylum-media/2.png"
          height="1200"
        />      
      
        <div id="clockContainer" @click.native="ingame('game_on')"><span class="text light large narrowSpacing" v-html="timeFormat(GAME.TIME)" /><br /></div>
        <div v-if="GAME.SUBTITLE" id="suggestionTextContainer">
            <div class="text light normal widespacing">
                <span>{{ GAME.SUBTITLE }}</span>
            </div>
        </div>
        <div id="buttonContainers">
            <button class="text light small wideSpacing" :class="{ colorized: GAME.HELPREQUEST }" @click="ingame('helprequest')">Help</button>
            <br />
            <!--button class="text light small wideSpacing" @click="ingame('replay')">Replay</button-->
        </div>

        <input @keyup="onPress" />
    </div>
</template>

<script setup>
// src="@/asylum-media/demo.mp4"
import { ref, onMounted, reactive, computed, watch, nextTick } from "vue";
const data = reactive({
    video: null,
});
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["game"]);

import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();
const game = "asylum";
const GAME = gameStore.getGame(game);

import timeFormat from "@/timeFormat.mjs";

const ingame = (run) => {
    $socket.emit("ingame", { game, run });
};

const getVideo = () => {
    if (!GAME) return null;
    if (!GAME.MEDIA) return null;
    if (!GAME.MEDIA.VIDEO) return null;
    return GAME.MEDIA.VIDEO;
};

const onPress = (e) => {
    alert(e);
};

onMounted(async () => {
    $socket.connect();
    await $socket.connection;
    gameStore.getUpdate(game);

    document.onkeypress = function (evt) {
        evt = evt || window.event;
        const charCode = evt.which || evt.keyCode;
        const trigger = String.fromCharCode(charCode);
        $socket.emit("keypress-trigger", { game, trigger });
    };

    $socket.on("game-param", async (payload) => {
        if (!payload[game]) return;
        if (!payload[game].MEDIA) return;
        if (!payload[game].MEDIA.VIDEO) return;
        data.video = payload[game].MEDIA.VIDEO;
        console.log("Start video: " + data.video);
        await nextTick();

        const videoElement = document.getElementById("video");

        videoElement.addEventListener("ended", () => {
            console.log("Ended video: " + data.video);
            data.video = null;
        });
    });

    //let elHtml = document.getElementsByTagName('html')[0]
    //elHtml.style.overflowY = 'hidden'
});
</script>

<script>
export default {
    // location: lift || secu
    path: "/kiosk/asylum/:location",
    name: "Kiosk",
    icon: "mdi-ghost-outline",
    //root: "Home",
    //section: "neverland",
};
</script>

<style></style>

<style scoped>
/*body {
    background: #0f0f0f;
}
html {
    margin: 0;
    padding: 0;
    overflow: hidden !important;
}*/

.non-selectable {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

@font-face {
    font-family: "asylum-primary";
    src: url("@/asylum-font/D250_blublop-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "asylum-secondary";
    /*src: url("@/asylum-font/PapakiloLight.ttf") format("truetype");*/
    font-weight: normal;
    font-style: normal;
}
/* filter: hue-rotate(170deg); */
#backgroundPicture {    
    position: fixed;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
#buttonContainers {
    position: absolute;
    width: 100%;
    height: fit-content;
    z-index: 3;
    bottom: 50px;
    text-align: center;
}
#clockContainer {
    position: absolute;
    width: 100%;
    height: fit-content;
    z-index: 3;
    top: 30px;
    text-align: center;
}
#suggestionTextContainer {    
    position: absolute;
    width: 100%;
    height: fit-content;
    z-index: 10;
    bottom: 500px;
    padding: 10px;
    text-align: center;
}
#skullContainer {
    position: absolute;
    width: 120mm;
    height: 120mm;
    left: 50%;
    top: 20vh;
    margin-left: -60mm;
    z-index: 3;
}
#videoContainer {
    z-index: 2;
}
#video {
    position: fixed;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.text {
    font-family: "asylum-primary";
    /* font-family: "asylum-secondary" */
   color: #202020;
}
.light {
    
}
.large {
    font-size: 6em;
}
.normal {    
    font-size: 100px;
  	color: #060606;
    background: url('@/asylum-media/1.png') 0 0 / cover no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.small {
    font-size: 50px;
}
.narrowSpacing {
    letter-spacing: 5px;
    text-indent: 5px;
}
.wideSpacing {
    letter-spacing: 15px;
    text-indent: 15px;
}

.colorized {
    color: orange;
}

/* fade video in out*/

.fade-enter-active,
.fade-leave-active {
    transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
