<template>
    <div id="kiosk-cont">
        <img id="backgroundPicture" src="@/chernobyl-media/background.jpg" height="1200" />
        <img id="backgroundOverlay" height="1200" />
        <div id="clock">
            <div id="main-clock">
                <div v-if="GAME.OBJECT" class="monitor-container">
                    <div class="monitor">
                        <div v-if="!GAME.ON" class="overlay-playbutton" @click="send('game_on')" />
                        <div v-if="GAME.ON" class="game-texts">
                            <div class="crt" />
                            <div>
                                <div class="slow-typer">*** АВАРИЙНАЯ СИТУАЦИЯ В БЛОКЕ 4 ***</div>
                                <div class="slow-typer second-line">*** КОД КРАСНЫЙ ***</div>
                            </div>
                            <div class="clock">
                                <div class="slow-typer third-line">РАСЧЕТ КРИТИЧЕСКОГО ВРЕМЕНИ РЕАКЦИИ<span class="blink tentimes">...</span></div>
                                <div class="slow-typer fourth-line">
                                    <!--game_time class="time" /-->
                                </div>
                            </div>

                            <button class="helpbutton delayed-display-11-sec" v-on:click="help()">Help</button>

                            <div class="hints" v-if="GAME.TEXT">
                                <div v-if="GAME.TEXT">
                                    <div class="slow-typer blink" v-if="GAME.TEXT"><span class="blink">СООБЩЕНИЕ</span></div>
                                    <div class="slow-typer second-line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="GAME.OBJECT" class="pa-2 justify-center hint-cont">
                    <v-row justify="center">
                        <v-img id="kiosk-hintimg" contain max-height="1200" max-width="1000" :src="GAME.OBJECT"></v-img>
                    </v-row>
                </div>
            </div>
        </div>
        <div id="subtitle">
            <p id="subtitle-text" v-if="GAME.SUBTITLE">{{ GAME.SUBTITLE }}</p>
        </div>
        <div id="controlls">
            <v-btn id="help-button" v-on:click="send('helprequest')" class="pa-3"> Help </v-btn>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["game"]);

import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();
const game = "chernobyl";
const GAME = gameStore.getGame(game);

import timeFormat from "@/timeFormat.mjs";

const ingame = (run) => {
    $socket.emit("ingame", { game, run });
};

const onPress = (e) => {
    alert(e);
};

onMounted(async () => {
    $socket.connect();
    await $socket.connection;
    gameStore.getUpdate(game);

    document.onkeypress = function (evt) {
        evt = evt || window.event;
        const charCode = evt.which || evt.keyCode;
        const trigger = String.fromCharCode(charCode);
        $socket.emit("keypress-trigger", { game, trigger });
    };
});
</script>

<script>
export default {
    // location: lift || secu
    path: "/kiosk/chernobyl/:location",
    name: "Kiosk",
    icon: "mdi-piggy-bank-outline",
    //root: "Home",
    //section: "neverland",
};
</script>

<style scoped>
/*@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
*/
@font-face {
    font-family: "chernobyl-plain";
    src: url("@/chernobyl-fonts/VT323-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

#backgroundPicture {
    position: fixed;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
#backgroundOverlay {
    --green-opaque: rgba(17, 177, 43, 0.7);
    --purple-opaque: rgba(5, 36, 114, 0.7);
    position: fixed;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(72deg, var(--green-opaque) 15%, var(--purple-opaque) 100%);
}
#kiosk-hintimg {
    opacity: 0.8;
    /*filter: grayscale(100%);*/
}

#help {
    width: 200px;
    height: 50px;
    background-color: var(--color);
    user-select: none;
    letter-spacing: 0.2em;
    font-size: 24px;
}

#clock {
    font-family: var(--themefont), monospace;
    color: var(--v-primary-base);
    margin-top: 90px;
}
#help_on_image {
    margin: 20px;
}
#subtitle {
    z-index: 1000;
    position: absolute;
    width: 98vw;
    height: 23vh;
    top: 70vh;
    left: 1vw;
    /*background-color: #0ff7;/**/
}
#subtitle-text {
    font-family: var(--themefont), monospace;
    font-size: 50;
    background-color: #0007;
    color: #fff;
    user-select: none;
}
#controlls {
    z-index: 2000;
    position: absolute;
    width: 100vw;
    height: 8vh;
    top: 93vh;
    left: 0vw;
    user-select: none;
}
#help-button {
    width: 20vw;
    height: 5vh;
    background-color: var(--color);
    user-select: none;
    letter-spacing: 0.6em;
    font-size: 20;
}
/* monitor and container*/
.monitor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* font-family: "VT323", monospace; */
.monitor {
    width: 1003px;
    height: 630px;
    background-image: url("chernobyl-media/screen.png");
    background-size: 1003px 630px;
    /*font-family: "chernobyl-plain";*/
    font-family: "VT323", monospace;
    color: #33ff33;
    font-size: 22px;
    letter-spacing: 0.15em;
    user-select: none;
    z-index: 2;
    text-align: center;
}
.monitor .game-texts,
.monitor,
.disconnected {
    margin-top: 70px;
}
.monitor .clock {
    margin-top: 50px;
}
.monitor .overlay-playbutton {
    width: 1003px;
    height: 630px;
}
.monitor .helpbutton {
    opacity: 0;
    margin-top: 50px;
    height: 60px;
    border: 4px solid var(--v-kioskTextColor);
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
}
.monitor .helpbutton:hover {
    background-color: #135813;
}
.monitor .hints {
    margin-top: 30px;
}

/* monitor's animations */
.monitor .delayed-display-11-sec {
    animation-name: delayed;
    animation-duration: 1s;
    animation-delay: 11s;
    -webkit-animation-delay: 11s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
.monitor .blink {
    animation-name: blinker;
    animation-duration: 1s;
}
.monitor .blink.tentimes {
    animation-iteration-count: 10;
}
.monitor .blink.forever {
    animation-iteration-count: 1000;
}

.monitor .slow-typer {
    width: 0px;
    overflow: hidden;
    /*border-right: 2px solid var((--v-kioskTextColor);*/
    white-space: nowrap;
    margin: 0 auto;
    animation: typing 3.5s steps(40, end); /*,    
    blink-caret .75s step-end infinite;*/
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
.monitor .slow-typer.second-line {
    animation-delay: 2.1s;
    -webkit-animation-delay: 2.1s;
}
.monitor .slow-typer.third-line {
    animation-delay: 4.4s;
    -webkit-animation-delay: 4.4s;
}
.monitor .slow-typer.third-line-word-wrap {
    animation-delay: 4.4s;
    -webkit-animation-delay: 4.4s;
    overflow: default;
    /*width: valamekkora*/
    /*text-align: center*/
}
.monitor .slow-typer.fourth-line {
    animation-delay: 6;
    -webkit-animation-delay: 6s;
}
.monitor .slow-typer.fifth-line {
    animation-delay: 8s;
    -webkit-animation-delay: 8s;
}
@keyframes delayed {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes blinker {
    10% {
        opacity: 0;
    }
}
@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.mainclock .time_display {
    color: var(--v-text-base);
    /*mix-blend-mode: color-burn;*/
}
</style>
