<template>
    <v-toolbar density="compact">
        <v-btn class="mx-2" fab dark color="primary" @click="save()" size="x-large">
            <v-icon dark> mdi-content-save </v-icon>
        </v-btn>
        <v-btn class="mx-2" fab dark color="primary" @click="remove()" size="x-large">
            <v-icon dark> mdi-delete </v-icon>
        </v-btn>
        <v-spacer />

        <v-spacer />

        <v-btn class="mx-2" fab dark color="primary" @click="editor()" size="x-large">
            <v-icon dark> mdi-application-edit </v-icon>
        </v-btn>
    </v-toolbar>
    <v-card class="pa-4 bg-grey-lighten">
        <vuetiform-poliform v-if="data.document._id" :format="data.format" v-model="data.document" v-model:valid="data.valid" />
    </v-card>
    <pre>
        {{ data }}
    </pre>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import VuetiformPoliform from "@/vuetiform/VuetiformPoliform.vue";
import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";

const router = useRouter();
const route = useRoute();

const key = "timeline-" + route.params.database + "-" + route.params.id;

import { useTimelineStore } from "@/store/timelineStore.mjs";
const store = useTimelineStore();

// @update="update"
const data = reactive({
    database: route.params.database,
    _id: route.params.id,
    format: { no: true },
    document: { no: true },
    valid: true,
    key: 0,
});
const adata = reactive({});

const editor = () => {
    router.push("/gui/" + route.params.database + "/timeline/" + route.params.id);
};

const save = () => {
    $socket.emit("timeline-editor-save", { database: route.params.database, document: data.document, _id: route.params.id }, (o) => {
        //editor();
        update();
    });
};
const remove = () => {
    $socket.emit("timeline-editor-remove", { database: route.params.database, document: data.document, _id: route.params.id }, (o) => {
        router.push("/");
    });
};

const update = (fn) => {
    $socket.emit("timeline-editor-update", { database: route.params.database, document: data.document, _id: route.params.id }, (o) => {
        // the v-if hack prevents the v-model overriding initial data
        Object.assign(data.document, o.document);
        Object.assign(data.format, o.format);
    });
};

onMounted(async () => {
    await $connection();
    update();
});
</script>
<script>
export default {
    path: "/timeline-editor/:database/timeline/:id",
    name: "timeline-editor",
    icon: "mdi-monitor-dashboard",
    sort: 110,
    root: "Home",
};
</script>
<style scoped></style>
