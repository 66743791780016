import { defineStore } from "pinia";
import { ref, computed, reactive } from "vue";

export const useTimelineStore = defineStore("Timeline-store", () => {
    const data = reactive({});
    const handlers = {};
    async function initStore() {
        await $connection();

        $socket.on("update-timeline", (e) => {
            Ł(e);
            for (const key in e) {
                if (!data[key]) data[key] = {};
                for (const i in e[key]) {
                    if (!data[key][i]) data[key][i] = {};
                    Object.assign(data[key][i], e[key][i]);
                }
                if (handlers[key]) for (const fn of handlers[key]) fn();
            }
        });
    }

    initStore();

    function init({ database, _id }) {
        $socket.emit("update-timeline-document", { database, _id });

        const key = "timeline-" + database + "-" + _id;
        const o = { database, _id, key, dataset: [] };
        const handler = [];
        handlers[key] = handler;
        if (!data[key]) data[key] = o;
        return { key, handler };
    }

    return { data, init };
});

/************ usage: ****************\

import { useTimelineStore } from "@/store/timelineStore.mjs";
const store = useTimelineStore();

\************************************/
