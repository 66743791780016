<template>
    <v-card v-if="data.error"><v-alert type="error" :title="data.error" icon="mdi-alert-circle" variant="tonal"></v-alert></v-card>
    <v-card v-else v-resize="onResize" class="pa-4 bg-grey-lighten" :key="database + '.' + collection + '.' + query" :loading="data.loading">
        <v-card-item>
            <v-container fluid>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="4">
                        <p class="pl-3">
                            <collection-info :database="database" :collection="collection" :query="query" :data="data" />
                        </p>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" align-self="center">
                        <collection-pagination :count="data.count" :page="data.page" :itemsPerPage="data.itemsPerPage" @update="setPage" />
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <v-sheet class="d-flex flex-wrap">
                            <v-sheet class="flex-1-1-100 ma-2 pa-2">
                                <v-text-field
                                    v-model="data.searchterm"
                                    density="compact"
                                    variant="solo"
                                    label="##&en Search ##&hu Keresés ##"
                                    prepend-icon="mdi-magnify"
                                    single-line
                                    hide-details
                                    clearable
                                ></v-text-field>
                            </v-sheet>
                            <v-sheet class="flex-1-0-25 ma-2 pa-2">
                                <v-btn rounded size="60" @click="data.hashlogic = !data.hashlogic"> {{ data.hashlogic ? "∧" : "∨" }}</v-btn>
                            </v-sheet>
                            <v-sheet class="flex-fill pa-2">
                                <hashtags-combobox v-if="data.hashtags" v-model="data.hashterm" :items="data.hashtags" class="ma-2" />
                            </v-sheet>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-item>

        <collection-table
            :host="data.host"
            :database="database"
            :collection="collection"
            :height="resizedHeight"
            :documents="searchedDocuments()"
            :format="data.format"
            :readonly="data.readonly"
            :sortable="data.sortable"
            :key="data.key"
        />
        <v-card-actions class="pa-10">
            <v-spacer />
            <v-btn icon size="large" @click.native="data.debug = !data.debug" color="blue">
                <v-icon>mdi-triangle-small-down</v-icon>
            </v-btn>
            <v-spacer />
        </v-card-actions>
        <div v-if="data.debug">
            <!--v-card-actions class="pa-10">
                <v-btn icon class="bg-red ml-6" size="large" @click.native="dropCollection()">
                    <v-icon light>mdi-database-remove</v-icon>
                </v-btn>
            </v-card-actions-->
            DEV / DEBUG DATA<br />
            <pre>{{ data }}</pre>
        </div>
    </v-card>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["database", "collection", "query", "redirecting"]);

const database = props.database;
const collection = props.collection;
const query = props.query;

import HashtagsCombobox from "@/vuetiform-components/HashtagsCombobox.vue";
import CollectionInfo from "@/database-components/CollectionInfo.vue";
import CollectionPagination from "@/database-components/CollectionPagination.vue";
import CollectionTable from "@/database-components/CollectionTable.vue";
import getFieldValue from "@/database-components/getFieldValue.mjs";

const data = reactive({
    name: "",
    page: 1,
    itemsPerPage: 1000,
    count: 0,
    total: 0,
    searchterm: null,
    host: null,
    readonly: true,
    debug: false,
    format: {},
    documents: [],
    hashtags: null,
    hashterm: null,
    hashlogic: true,
    sortable: false,
    key: 1,
    loading: true,
    error: null,
});

const searchedDocuments = () => {
    let filtered = data.documents;

    if (data.hashterm)
        if (data.hashterm.length > 0) {
            if (!data.hashlogic)
                filtered = filtered.filter((document) => {
                    if ((document._hashtags || []).length < 1) return false;
                    return document._hashtags.some((tag) => data.hashterm.includes(tag));
                });
            if (data.hashlogic)
                filtered = filtered.filter((document) => {
                    const docHashtags = document._hashtags || [];
                    if (docHashtags.length < 1) return false;
                    return data.hashterm.every((tag) => docHashtags.includes(tag));
                });
        }

    if (data.searchterm)
        if (data.searchterm.length > 0) {
            filtered = filtered.filter((document) => {
                let str = "";
                Object.keys(document)
                    .filter((s) => s[0] !== "_")
                    .forEach((key) => {
                        str += " " + getFieldValue(data.format, document, key) + " ";
                    });
                return str.includes(data.searchterm);
            });
        }
    return filtered;
};
/*
// basic functions
const openDocument = (document) => {
    $socket.emit("open-document", { database, collection, id: (document || {})._id }, ({ database, collection, id }) => {
        router.push("/document/" + database + "/" + collection + "/" + id);
    });
};

const openCollection = () => {
    router.push("/database/" + database + "/" + collection);
};

const dropCollection = () => {
    $socket.emit("drop-collection", { database, collection }, () => {
        router.push("/database");
    });
};

const openRemote = () => {
    if (!data.host) return;
    window.location.href = "https://" + data.host + "/database/" + data.database + "/" + data.collection;
};
*/
const resizedHeight = ref(0);
const onResize = () => {
    resizedHeight.value = window.innerHeight - 560;
};

const setPage = (page) => {
    data.page = page;
    update();
};

let transfer = false;
const update = (a) => {
    if (transfer) return;
    transfer = true;
    data.loading = true;
    const page = data.page;
    const itemsPerPage = 1000;
    $socket.emit("update-collection", { database, collection, query, page, itemsPerPage }, (o) => {
        Object.assign(data, o);
        transfer = false;
        data.loading = false;
    });
};

onMounted(async () => {
    await $connection();
    update();
    $socket.removeListener("update-collection");
    $socket.on("update-collection", (u) => {
        if (u.database !== database) return;
        if (u.collection !== collection) return;
        update();
    });
});
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>
