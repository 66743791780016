<template>
    <v-container v-if="isObject()" fluid>
        <v-row>
            <template v-for="(toplevel, top, i) in props.structure" :key="i">
                <v-col cols="12" :style="{ paddingLeft: computedPadding + 'px' }">
                    <strong class="text-h6 font-weight-bold" :style="{ fontSize: computedFontSize + 'px !important' }">{{ top }}</strong>
                </v-col>
                <vuetifind-selection :structure="toplevel" @leftClickElement="leftClickElement" @rightClickElement="rightClickElement" :_depth="(props._depth || 0) + 1" />
            </template>
        </v-row>
    </v-container>

    <template v-if="isArray()">
        <v-card
            width="250"
            height="100"
            v-for="(element, k) in structure"
            :key="k"
            class="ma-2 pa-2"
            outlined
            tile
            @click.native="leftClickElement(element)"
            @contextmenu="rightClickElement(element)"
        >
            <p class="text-center"><v-icon class="pa-6 text-center" :icon="element.icon || 'mdi-adjust'" :color="element.color || ''"></v-icon></p>
            <p class="text-center">{{ element.name }}</p>
        </v-card>
    </template>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import VuetifindSelection from "@/vuetifind-components/VuetifindSelection.vue";
import { isProxy, toRaw } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const isObject = () => {
    return Object.prototype.toString.call(props.structure) === "[object Object]";
};
const isArray = () => {
    return Object.prototype.toString.call(props.structure) === "[object Array]";
};

const props = defineProps(["structure", "icon", "_depth"]);
const emit = defineEmits(["leftClickElement", "rightClickElement"]);

const computedPadding = computed(() => {
    return props._depth * 25;
});

const computedFontSize = computed(() => {
    const baseSize = 26; // base font size in pixels
    return Math.max(baseSize - (props._depth || 0) * 4, 2); // minimum font size of 12px
});

const leftClickElement = (e) => {
    const o = toRaw(e);
    emit("leftClickElement", o);
};
const rightClickElement = (e) => {
    const o = toRaw(e);
    emit("rightClickElement", o);
};
</script>
<script>
export default {
    inheritAttrs: false,
    name: "vuetifind-selection",
};
</script>
