/* global $socket:false, $connection:false */

import { defineStore } from "pinia";
import { reactive } from "vue";

export const useGameStore = defineStore("game-store", () => {
    const games = reactive({});

    function init(game) {
        if (games[game] === undefined) games[game] = {};
    }

    async function getData() {
        await $connection();

        $socket.on("game", (payload) => {
            //Ł("game", payload);
            for (const game in payload) {
                init(game);
                Object.assign(games[game], payload[game]);
            }
        });
        $socket.on("game-param", (payload) => {
            //Ł("game-param", payload);
            for (const game in payload) {
                init(game);
                for (const p in payload[game]) games[game][p] = payload[game][p];
            }
        });
    }

    async function getUpdate(game) {
        await $connection();
        $socket.emit("update", { game }, (payload) => {
            for (const game in payload) {
                init(game);
                Object.assign(games[game], payload[game]);
            }
        });
    }

    getData();

    function getGame(game) {
        init(game);
        return games[game];
    }
    function getterForGame(game) {
        init(game);
        return () => {
            return games[game];
        };
    }
    return { games, getGame, getterForGame, getUpdate };
});

/************ usage: ****************\

import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();


\************************************/
