export default function timeFormat(t) {
    // console.log("timeform: ", t);
    if (isNaN(t)) return "--";
    var str = "";
    var m = Math.floor(t / 60);
    var s = t - m * 60;

    if (m < 10) str += "0" + m;
    else str = String(m);

    str += ":";

    if (s < 10) str += "0" + s;
    else str += "" + s;

    if (t <= 0) str = "00:00";

    return str;
}
