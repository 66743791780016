<template>
    <video id="video" autoplay loop muted>
        <source src="@/moneyheist-media/lift-background.mp4" type="video/mp4" />
        <a href="@/moneyheist-media/lift-background.mp4">MP4</a>
    </video>
    <div id="screen" class="Screen">
        <div class="OuterContainerClass">
            <div id="flexContainer">
                <div id="textContainer" @click="ingame('game_on')">
                    <div class="MatrixTextEffect">
                        <span v-html="timeFormat(GAME.TIME)" /><br />
                        <span style="white-space: pre">{{ GAME.SUBTITLE }}</span>
                    </div>
                </div>
                <div id="buttonContainer" class="Pulse">
                    <button class="MatrixTextEffect" @click="ingame('helprequest')" id="helpRequestButton">Help</button>
                </div>
            </div>
        </div>
    </div>
    <input @keyup="onPress" />
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["game"]);

import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();
const game = "jailbreak";
const GAME = gameStore.getGame(game);

import timeFormat from "@/timeFormat.mjs";

const ingame = (run) => {
    $socket.emit("ingame", { game, run });
};

const onPress = (e) => {
    alert(e);
};

onMounted(async () => {
    $socket.connect();
    await $socket.connection;
    gameStore.getUpdate(game);

    document.onkeypress = function (evt) {
        evt = evt || window.event;
        const charCode = evt.which || evt.keyCode;
        const trigger = String.fromCharCode(charCode);
        $socket.emit("keypress-trigger", { game, trigger });
    };
});
</script>

<script>
export default {
    // location: lift || secu
    path: "/kiosk/jailbreak/:location",
    name: "Kiosk",
    icon: "mdi-piggy-bank-outline",
    //root: "Home",
    //section: "neverland",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
#buttonContainer {
    margin-top: 50px;
    height: 250px;
    min-height: 250px;
    flex-basis: 250px;
}
#content {
    padding: 36px;
    font-size: 48px;
    color: white;
    text-shadow: 2px 2px 4px #000000;
}
#flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 100vh;
}
#helpRequestButton {
    width: 350px;
    height: 128px;
    border: 4px solid #fffebf;
    color: #fffebf;
    background: #fffebf55;
    embed: none;
    text-transform: uppercase;
    font-size: 60px;
}
#screen {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    text-align: center;
}
#textContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 75px;
    flex-grow: 1;
    height: 100%;
    user-select: none;
}
#video {
    position: fixed;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* ---- https://codepen.io/freelesio/pen/MWQaGPb ----- */

.Screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    animation: ScreenEffect 3.05s ease infinite;
    cursor: pointer;
}

@keyframes ScreenEffect {
    0% {
        opacity: 0.7;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.7;
    }
}

.Pulse {
    animation: Pulse 1s ease infinite;
}

@keyframes Pulse {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

.OuterContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 5% 5% 5% 5%;
}

.MatrixTextEffect {
    color: #fffebf;
    font-family: "VT323", monospace;
    font-size: 48px;
    text-shadow: 0 0 5px #fffd78, 2px 2px 8px #000000, 6px 6px 18px #220000, -2px -2px 3px #005500, -3px -2px 8px #000000;
}

body {
    background: #0f0f0f;
}

html {
    margin: 0;
    padding: 0;
    overflow: hidden !important;
}
</style>
