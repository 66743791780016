<template>
    <v-card-title>
        <div class="text-h5 pa-1 mb-4">{{ data.title || "##&en Connecting .. ##&hu Kapcsolódás ##" }}</div>
    </v-card-title>
    <v-card-text id="login-form">
        <vuetiform-poliform :format="data.format" v-model="data.form" v-model:valid="data.valid" :key="v" />
    </v-card-text>
    <v-card-subtitle v-if="data.alert">
        <v-alert dense :color="data.alert_color || 'white'"> {{ data.alert || "" }}</v-alert>
    </v-card-subtitle>
    <v-card-actions>
        <v-spacer />
        <v-btn :disabled="data.valid !== true || !data.label_primary" @click="primary()">{{ data.label_primary }}</v-btn>
    </v-card-actions>
</template>

<script setup>
import VuetiformPoliform from "@/vuetiform/VuetiformPoliform.vue";

import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

const emit = defineEmits(["complete"]);
const props = defineProps(["autologin"]);

const router = useRouter();
const data = reactive({});

import { useSessionStore } from "@/store/sessionStore.mjs";
const sessionStore = useSessionStore();
  
let v = 0;

const post = async (op) => {
    Ł(op);
    const adat = {
        form: data.form,
    };
    const response = await fetch("/post-login.json", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ op, adat }),
    });

    if (!response.ok) return console.log("[post] There was an error. ", response);

    const res = await response.json();
    Object.assign(data, res.data);
  Ł(res.data);
  
    if (res.socketClose) $socket.close();
  	if (res.resetSession) await sessionStore.resetSession();
    if (res.emit) emit(res.emit);
    
  	if (res.socketOpen) $socket.open();
    if (res.route) router.push(res.route);
    if (res.alert) alert(res.alert);
    v++;
};

const primary = () => {
    if (data.op_primary) post(data.op_primary);
};

onMounted(async () => {
    await post("init");

    let element = document.getElementById("login-form");
    if (element)
        element.addEventListener("keydown", (e) => {
            console.log("login-form keydown", e.key);

            if (e.key !== "Enter") return;
            primary();
        });
});
</script>
