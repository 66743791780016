<template>
    <v-dialog v-model="data.dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
        <template v-slot:activator="{ props }">
            <v-btn
                @click="data.dialog = !data.dialog"
                variant="elevated"
                :color="selection.size > 0 ? 'primary' : 'gray'"
                class="ma-1"
                :disabled="selection.size === 0"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon dark> mdi-file-document-plus-outline </v-icon> ##&en Patch ##&hu Patchelés ##</p>
            </v-btn>
        </template>
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-items>
                    <v-btn variant="text" @click="applyPath()"> ##&en Apply Patch ##&hu Patchelés alkalmazása ## </v-btn>
                </v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="data.dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <vuetiform-poliform :format="getPatchFormat(format)" v-model="data.patch" v-model:valid="data.valid" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, toRaw } from "vue";
import VuetiformPoliform from "@/vuetiform/VuetiformPoliform.vue";

import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["host", "database", "collection", "selection", "format", "height", "activator", "readonly", "sortable"]);
const host = props.host;
const database = props.database;
const collection = props.collection;
const activator = props.format.active !== undefined;
const hostname = (ß.HOSTNAME || "localhost").split(".")[0];

const id = "PATCH";
const data = reactive({
    format: props.format,
    dialog: false,
    patch: {},
    valid: true,
});

function getPatchFormat() {
    const patch = {};
    Object.keys(props.format).forEach((k) => {
        const p = clone(props.format[k]);
        delete p.default;

        patch[k] = p;
    });
    delete patch.name;
    return patch;
}

const applyPath = () => {
    const selection = Array.from(props.selection);
    const patch = toRaw(data.patch);
    $socket.emit("apply-patch-documents", { database, collection, selection, patch }, () => {
        data.dialog = false;
    });
};
</script>

<style scoped></style>
