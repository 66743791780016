<template>
    <v-card width="2000">
        <v-toolbar density="compact">
            <pre> Values: {{ data.min }} .. {{ data.max }} | Timespan: {{ Math.floor(data.timespan_ms / 1000) }} seconds | Selection {{ selectionInfo }}</pre>
        </v-toolbar>
        <svg></svg>
    </v-card>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import SENSOR_PROPERTIES from "@/1000.SENSOR_PROPERTIES.mjs";

const props = defineProps(["macid", "data"]);
const macid = props.macid;
const data = props.data;
const selectionInfo = ref("?");

const WIDTH = 2000;
const HEIGHT = 1000;
const memorydata = reactive([]);

import { io } from "socket.io-client";

onMounted(async () => {
    const svg = d3.select("svg").attr("width", WIDTH).attr("height", HEIGHT);
    const view = svg
        .attr("viewBox", "0 0 " + WIDTH + " " + HEIGHT)
        .style("background-color", "lightgray")
        .style("cursor", "default");
    const bkg = svg.append("svg").attr("id", "beacon-background");
    const grs = svg.append("svg").attr("id", "beacon-graphs");
    const box = grs.append("svg").attr("id", "box").append("rect").attr("fill", "#00F2").attr("stroke", "none").attr("stroke-width", 0);

    const graphs = {};
    for (const p in SENSOR_PROPERTIES)
        graphs[p] = grs.append("svg").attr("id", p).append("path").attr("fill", "none").attr("stroke", SENSOR_PROPERTIES[p].color).attr("stroke-width", 2); //.selectAll("g").select("path");

    const screenUpdate = () => {
        const timespan_ms = data.timespan_ms;
        let time = data.time;
        if (data.play) time = new Date().getTime();
        const timefrom_ms = Math.floor(time - timespan_ms);

        background(bkg, { timefrom_ms, timespan_ms });

        const x = Math.min(data.x[0], data.x[1]);
        const y = Math.min(HEIGHT - data.y[0], HEIGHT - data.y[1]);
        const w = Math.abs(data.x[0] - data.x[1]);
        const h = Math.abs(data.y[0] - data.y[1]);

        box.attr("x", x).attr("y", y).attr("width", w).attr("height", h);

        for (const p in graphs) {
            if (data.graphs.includes(p)) graphs[p].style("visibility", "visible");
            else graphs[p].style("visibility", "hidden");
            const mem = memorydata
                .filter((e) => e[p] !== undefined)
                //.filter((e) => timefrom_ms < e.timestamp && e.timestamp < time)
                .map((e) => [e.timestamp, e[p]]);
            graph(graphs[p], { data: mem, timefrom_ms, timespan_ms, min: data.min, max: data.max });
        }

        const unit = SENSOR_PROPERTIES[data.graphs[0]].unit;

        selectionInfo.value = "Timespan " + Math.floor(((Math.abs(data.x[0] - data.x[1]) / WIDTH) * data.timespan_ms) / 1000) + " seconds";
        selectionInfo.value += " Values min: " + Math.floor(data.min + (Math.min(data.y[0], data.y[1]) / HEIGHT) * (data.max - data.min));
        selectionInfo.value += " max: " + Math.floor(data.min + (Math.max(data.y[0], data.y[1]) / HEIGHT) * (data.max - data.min)) + " " + unit;
    };
    await $connection();

    setInterval(screenUpdate, 100);

    const options = ß.SOCKETIO_OPTIONS || {};

    const connect = "https://" + ß.HOSTNAME + ":" + ß.SERVER_PORT + "/beacon-graph";
    console.log("[socket.io] " + connect, options);

    options.withCredentials = true;
    options.forceNew = true;

    const sensor_socket = io(connect, options);
    sensor_socket.connect();

    while (!sensor_socket.connected) await new Promise((resolve) => setTimeout(resolve, 100));

    //Ł("...");

    sensor_socket.emit("init-graph", macid, (o) => {
        memorydata.length = 0;
        Object.assign(memorydata, o.memorydata);
        //Ł("init-beacon-graph", o);
    });

    sensor_socket.on("addto-graph", (e) => {
        memorydata.push(e);
        //Ł("add-beacon-datapoint", e);
    });
});

import background from "@/beacon-background.d3";
import graph from "@/beacon-graph.d3";
</script>
<script>
import * as d3 from "d3";
</script>
