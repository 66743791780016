import * as d3 from "d3";

let now = new Date();

const timezoneOffset = now.getTimezoneOffset() * 60;

function getDate(n) {
    const date = new Date();
    date.setTime((n + timezoneOffset) * 1000);
    return date;
}

function displayFullDate(n) {
    const date = getDate(n);

    let month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;

    let day = date.getDate();
    if (day < 10) day = "0" + day;

    let hour = date.getHours();
    if (hour < 10) hour = "0" + hour;

    let min = date.getMinutes();
    if (min < 10) min = "0" + min;

    let sec = date.getSeconds();
    if (sec < 10) sec = "0" + sec;

    return date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
}

function displayYear(n) {
    const date = getDate(n);
    return date.getFullYear();
}

function displayYearMonth(n) {
    const date = getDate(n);
    return date.getFullYear() + " " + getMonthName(date);
}

function getWeekdayName(date) {
    let w = date.getDay();

    if (w === 0) return "##&en Sunday ##&hu Vasárnap ##";
    if (w === 1) return "##&en Monday ##&hu Hétfő ##";
    if (w === 2) return "##&en Tuesday ##&hu Kedd ##";
    if (w === 3) return "##&en Wensday ##&hu Szerda ##";
    if (w === 4) return "##&en Thursday ##&hu Csütörtök ##";
    if (w === 5) return "##&en Friday ##&hu Péntek ##";
    if (w === 6) return "##&en Saturday ##&hu Szombat ##";
    return "?";
}

function getMonthName(date) {
    let m = 1 + date.getMonth();

    if (m === 1) return "##&en January ##&hu Január ##";
    if (m === 2) return "##&en February  ##&hu Február ##";
    if (m === 3) return "##&en March ##&hu Március ##";
    if (m === 4) return "##&en April  ##&hu Április ##";
    if (m === 5) return "##&en May ##&hu Május ##";
    if (m === 6) return "##&en June ##&hu Június ##";
    if (m === 7) return "##&en July ##&hu Július ##";
    if (m === 8) return "##&en August ##&hu Augusztus ##";
    if (m === 9) return "##&en September ##&hu Szeptember ##";
    if (m === 10) return "##&en October ##&hu Október ##";
    if (m === 11) return "##&en November ##&hu November ##";
    if (m === 12) return "##&en December ##&hu December ##";
    return "??";
}

function displayMonthDay(n) {
    const date = getDate(n);

    //let month = date.getMonth() + 1;
    //if (month < 10) month = "0" + month;

    let dateday = date.getDate();
    if (dateday < 10) dateday = "0" + dateday;

    return getMonthName(date) + " " + dateday + ". " + getWeekdayName(date);
}

function displayMonth(n) {
    const date = getDate(n);

    //let month = date.getMonth() + 1;
    //if (month < 10) month = "0" + month;

    let dateday = date.getDate();
    if (dateday < 10) dateday = "0" + dateday;

    return getMonthName(date) + " " + dateday + ".";
}

function displayHoursMinutes(n) {
    const date = getDate(n);

    let hour = date.getHours();
    if (hour < 10) hour = "0" + hour;

    let min = date.getMinutes();
    if (min < 10) min = "0" + min;

    return hour + ":" + min;
}

function displaySeconds(n) {
    const date = getDate(n);
    let sec = date.getSeconds();
    if (sec < 10) sec = "0" + sec;
    return ":" + sec;
}

// TODO: width > 3

function timespanArray(timefrom, timespan, width, unit, mark, step, formatter) {
    const a = [];

    const slot = width / timespan;
    const size = slot * step;
    // there is a pre-field, from the friction of the first step
    const pres = timefrom % step;
    const prew = slot * (step - pres);

    {
        // this is the scope for the first friction / or the first element
        const o = {};
        const t = timefrom;
        o.t = t;
        o.title = displayFullDate(t);
        o.width = prew;
        if (o.width < 3) o.width = 3;
        o.x = 0;
        if (t % unit === 0) o.label = formatter(t);
        a.push(o);
    }

    let x = prew;
    for (let i = 0; i < timespan; i += step) {
        const o = {};
        const t = timefrom + i + step - pres;
        o.t = t;
        o.title = displayFullDate(t);
        o.width = size;
        if (o.width < 3) o.width = 3;
        o.even = t % mark === 0;
        o.x = x;
        if (t % unit === 0) o.label = formatter(t);
        a.push(o);
        x += size;
    }

    return a;
}

export function timespanSections(timefrom, timespan, width) {
    const gmtime = timefrom - timezoneOffset;

    if (timespan <= 15) return timespanArray(gmtime, timespan, width, 1, 1, 1, displaySeconds);
    if (timespan <= 60) return timespanArray(gmtime, timespan, width, 2, 2, 1, displaySeconds);
    if (timespan <= 300) return timespanArray(gmtime, timespan, width, 10, 5, 1, displaySeconds);

    if (timespan <= 600) return timespanArray(gmtime, timespan, width, 60, 30, 10, displayHoursMinutes);
    if (timespan <= 3000) return timespanArray(gmtime, timespan, width, 120, 120, 60, displayHoursMinutes);
    if (timespan <= 6000) return timespanArray(gmtime, timespan, width, 240, 120, 60, displayHoursMinutes);
    if (timespan <= 12000) return timespanArray(gmtime, timespan, width, 600, 300, 60, displayHoursMinutes);
    if (timespan <= 24000) return timespanArray(gmtime, timespan, width, 1800, 900, 300, displayHoursMinutes);
    if (timespan <= 43200) return timespanArray(gmtime, timespan, width, 3600, 1800, 900, displayHoursMinutes);
    if (timespan <= 86400) return timespanArray(gmtime, timespan, width, 7200, 3600, 900, displayHoursMinutes);
    if (timespan <= 172800) return timespanArray(gmtime, timespan, width, 7200, 3600, 1800, displayHoursMinutes);
    if (timespan <= 345600) return timespanArray(gmtime, timespan, width, 14400, 7200, 3600, displayHoursMinutes);

    if (timespan <= 1000000) return timespanArray(gmtime, timespan, width, 86400, 43200, 3600, displayMonthDay);
    if (timespan <= 2000000) return timespanArray(gmtime, timespan, width, 86400, 172800, 43200, displayMonth);

    if (timespan <= 10000000) return timespanArray(gmtime, timespan, width, 604800, 172800, 86400, displayMonth);
    if (timespan <= 50000000) return timespanArray(gmtime, timespan, width, 2419200, 1209600, 604800, displayMonth);
    //if (timespan <= 200000000) return timespanArray(gmtime, timespan, width,  14515200, 9676800, 4838400, displayYearMonth);

    if (timespan <= 1000000000) return timespanArray(gmtime, timespan, width, 31557600, 157788000, 2629800, displayYearMonth);
    if (timespan <= 10000000000) return timespanArray(gmtime, timespan, width, 315576000, 157788000, 31557600, displayYear);
    if (timespan <= 100000000000) return timespanArray(gmtime, timespan, width, 3155760000, 1577880000, 315576000, displayYear);
    if (timespan <= 1000000000000) return timespanArray(gmtime, timespan, width, 31557600000, 15778800000, 3155760000, displayYear);

    return [];
}
