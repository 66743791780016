import * as d3 from "d3";

export default function (properties, dataset, updater) {
    const { timefrom, timespan, width, height, selected = [], editable = false, dragging = false, selecting = false } = properties;

    function getStartDate(o) {
        if (o.startdate) return Math.floor(new Date(o.startdate).getTime() / 1000);
        if (o._id) return parseInt(o._id.substring(0, 8), 16);
        return Math.floor(new Date().getTime() / 1000);
    }
    function setPos(d, i) {
        return "translate(0," + i * height + ")";
    }
    //placeholder_background = svg.select("#d3database-main");
    const endtime = timefrom + timespan;
    const pixeltime = width / timespan;
    if (!updater.timelineDatagramClick)
        updater.timelineDatagramClick = (d) => {
            console.log("No updater.timelineDatagramClick", d);
        };

    function timeFilter(a) {
        return a.filter((o) => {
            const start_sec = getStartDate(o);
            const end_sec = start_sec + (o.duration || 60);
            if (end_sec < timefrom || timefrom + timespan < start_sec) return false;
            return true;
        });
    }

    function getColor(o) {
        if (o.color) return o.color;
        return "#FFDDDDDD";
    }
    function isSelected(d) {
        if (selected.includes(d.id)) return true;
        //if (!selected) return false;
        //if (selected === d) return true;
        //if (d._id !== undefined) if (selected._id === d._id) return true;
        return false;
    }
    function getStrokeWidth(d) {
        if (isSelected(d)) return 6;
        return 2;
    }
    // start drawing position
    function getStartX(o) {
        const st = getStartDate(o);
        if (st < timefrom) return 0;
        return (st - timefrom) * pixeltime;
    }
    function getItemHeight(o) {
        return 20;
    }
    function getStartY(o) {
        // should return between 10 and height - getItemHeight - 10
        const min = 10;
        const max = height - min - getItemHeight();
        const step = 20;
        return min + ((o.z * step) % max);
    }
    // drawing with width relative to position
    function getItemWidth(o) {
        const duration = o.duration || 360;
        const st = getStartDate(o);
        const et = st + duration;

        if (st < timefrom)
            if (endtime < et) return width;
            else return (et - timefrom) * pixeltime;

        if (endtime < et) return (endtime - st) * pixeltime;

        return duration * pixeltime;
    }
    function hasClippedDuration(o) {
        const duration = o.duration || 60;
        const st = getStartDate(o);
        const et = st + duration;

        if (endtime < et) return true;
        return false;
    }
    function hasClippedStart(o) {
        const st = getStartDate(o);

        if (st < timefrom) return true;
        return false;
    }
    function dragstarted(event, d) {
        if (!dragging) return; // updater.timelineDatagramClick(d);

        const element = d3.select(this);

        const w = getItemWidth(d);
        element.style("cursor", "ew-resize");

        d.click = true;
        d.fromx = d.x;
        d.fromw = getItemWidth(d);

        // this is a bit hackerish, but I didn't find any better solution yet. Works only in fullwidth screen
        const document_width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const ecx = (event.sourceEvent.clientX / document_width) * width;

        if (ecx < d.x + 30 && !hasClippedStart(d) && ecx < width - 30) {
            element.style("cursor", "e-resize");
            d.action = "beginning";
            return;
        }

        if (d.x + w - 30 < ecx && !hasClippedDuration(d) && ecx > 30) {
            element.style("cursor", "w-resize");
            d.action = "duration";
            d.fromw = getItemWidth(d);
            return;
        }

        d.action = "start";
    }
    function dragged(event, d) {
        if (!dragging) return;
        const element = d3.select(this);

        d.click = false;
        if (d.action === "start") element.attr("x", (d.x = event.x));
        if (d.action === "duration") element.attr("width", (d) => event.x + d.fromw - d.x);
        if (d.action === "beginning") element.attr("x", (d.x = event.x)).attr("width", (d) => d.fromw - d.x + d.fromx);
    }
    function dragended(event, d) {
        if (!dragging) return;
        const element = d3.select(this);
        element.style("cursor", "default");

        if (d.action === "start") {
            const start = getStartDate(d);
            const deltaT = Math.round((d.x - d.fromx) / pixeltime);
            d.startdate = new Date((start + deltaT) * 1000);
            updater.timlineDatagramUpdate(d, "startdate");
        }

        if (d.action === "duration") {
            const deltaT = Math.round((element.attr("width") - d.fromw) / pixeltime);
            if (d.duration + deltaT < 0) return element.attr("width", d.fromw);
            d.duration = d.duration + deltaT;
            updater.timlineDatagramUpdate(d, "duration");
        }

        if (d.action === "beginning") {
            const start = getStartDate(d);
            const deltaT = Math.round((d.x - d.fromx) / pixeltime);
            d.duration = d.duration - deltaT;
            d.startdate = new Date((start + deltaT) * 1000);
            updater.timlineDatagramUpdate(d, "startdate|duration");
        }

        updater.render();
        delete d.click;
        delete d.fromw;
        delete d.fromx;
        delete d.action;
    }
    const selections = d3
        .select("#timeline-content")
        .selectAll("svg")
        .data(dataset, (d) => d);

    selections.exit().remove();

    selections.attr("transform", setPos);

    const rows = selections
        .enter()
        .append("g")
        .attr("transform", setPos)
        .attr("id", (d, i) => "row-" + d.name);

    const list = rows
        .append("g")
        .selectAll("svg")
        .data((d) => timeFilter(d.items));

    list.exit().remove();

    const listgroup = list.enter().append("g");

    const dragHandler = d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended);

    const listelem = listgroup
        .append("svg")
        .attr("id", (d, i) => "item-" + d.name)
        .attr("width", getItemWidth)
        .attr("height", getItemHeight)
        .attr("x", function (d) {
            d.x = getStartX(d);
            return d.x;
        })
        .attr("y", function (d) {
            d.y = getStartY(d);
            return d.y;
        });

    if (dragging && editable) listelem.call(dragHandler);

    listelem.style("cursor", "not-allowed");
    if (selecting) listelem.style("cursor", "crosshair");
    if (dragging) listelem.style("cursor", "ew-resize");

    // Note: click does not work for some reason, the event wont fire unless drag is disabled.
    listelem.append("rect").attr("width", "100%").attr("height", "100%").attr("fill", getColor).attr("stroke", "black").attr("stroke-width", getStrokeWidth);

    const startbox = listelem.append("svg");

    // for start clipping
    startbox
        .append("rect")
        .attr("width", function (d) {
            if (getStartX(d) === 0) return 30;
            return 0;
        })
        .attr("height", getItemHeight)
        .style("fill", "url(#leadinGradient)");

    const endbox = listelem.append("svg");

    // for end clipping
    endbox
        .append("rect")
        .attr("x", (d) => getItemWidth(d) - 30)
        .attr("width", function (d) {
            if (hasClippedDuration(d)) return 30;
            return 0;
        })
        .attr("height", getItemHeight)
        .style("fill", "url(#leadoutGradient)");

    /// TEXT DRAG ////////////////

    function textdragstarted(event, d) {
        const shift = event.sourceEvent.shiftKey;
        const element = d3.select(this);
        element.raise();

        if (!shift) selected.length = 0;
        if (selected.includes(d.id)) return;
        selected.push(d.id);
        //element.style("cursor", "context-menu");
        if (!selecting) updater.timelineDatagramClick(d);
    }

    function textdragged(event, d) {
        //const element = d3.select(this);
        //element.raise();
    }

    function textdragended(event, d) {
        //const element = d3.select(this);
        //element.style("cursor", "default");
        updater.render();
    }

    const titletext = listgroup
        .append("svg")
        .attr("x", function (d) {
            d.x = getStartX(d);
            return d.x + 10;
        })
        .attr("y", function (d) {
            d.y = getStartY(d);
            return d.y - 10;
        });

    titletext
        .append("rect")
        .attr("width", (d) => 10 + d.name.length * 10)
        .attr("height", 20)
        .attr("fill", (d) => (isSelected(d) ? "#000" : "#FFF"))
        .attr("stroke", "black");

    titletext
        .append("text")
        .attr("font-family", "Monospace")
        .attr("x", 3)
        .attr("y", 15)
        .text((d) => d.name)
        .attr("fill", (d) => (isSelected(d) ? "#FFF" : "#000"))
        .attr("style", "pointer-events: none");

    const overlaySurface = titletext
        .append("rect")
        .attr("width", (d) => 10 + d.name.length * 10)
        .attr("height", 20)
        .attr("fill", "#F00")
        .attr("fill-opacity", 0)
        .attr("class", "clickable");

    overlaySurface.style("cursor", "pointer");
    overlaySurface.call(d3.drag().on("start", textdragstarted).on("drag", textdragged).on("end", textdragended));

    overlaySurface.on("click", (e, d) => {
        Ł("clicked", e, d);
    });
}
