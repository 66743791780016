<template>
    <div class="scrollable black">
        <div v-if="!socket.disconnected">
            <v-app-bar color="#003">
                <v-btn icon="mdi-home" @click="goHome()" color="white" />
                <v-btn icon="mdi-gamepad-variant" @click="router.push('/supervisor')" color="white" />
            </v-app-bar>

            <SystemSnackbars />

            <div style="padding-top: 0px">
                <v-container fluid class="white center" v-for="host of getHosts()">
                    <v-layout>
                        <v-row justify="center" no-gutters>
                            <v-btn block @click="router.push('/supervisor/' + host)">{{ host }}</v-btn>

                            <v-col sm="12" md="6" lg="6" xl="4" xxl="3" class="pa-5" v-for="ROOM in getRooms(host)">
                                <v-card class="ma-2 pa-2 white" min-width="340" max-width="900" min-height="250" :color="getCardColor(ROOM)">
                                    <v-card-text @click="openRoom(ROOM)" class="cardtop">
                                        <v-card-subtitle class="ip-address" style="white-space: pre; max-height: 130px"
                                            >{{ ROOM.host }}:{{ ROOM.port }} {{ getStatusOnline(ROOM.date) }} {{ ROOM.GAME.ON ? "game-on" : "" }}</v-card-subtitle
                                        >
                                        <v-card-title class="uppercase">{{ ROOM.name }}</v-card-title>
                                        <pre class="time_display center" v-html="timeFormat(ROOM.GAME.TIME)"></pre>
                                        <v-card-subtitle class="subtitle">{{ ((ROOM.GAME || {}).OBJECT || {}).name || "--" }}</v-card-subtitle>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-layout>
                </v-container>
            </div>
        </div>
        <div v-else>
            <v-alert
                density="compact"
                type="error"
                title="##&en Disconnected ##&hu Szétkapcsolva ##"
                text="##&en Connection to the application host is lost. ##&hu Az alkalmzásnak kapcsolata a kiszolgálójával megszakadt. ##"
            ></v-alert>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import SystemSnackbars from "@/application-components/SystemSnackbars.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { useSocketdataStore } from "@/store/socketdataStore.mjs";
const socket = useSocketdataStore();

const hostname = ß.HOSTNAME;
const local = hostname.startsWith("192.168.");

const goHome = () => {
    const hostname = ß.HOSTNAME;
    if (hostname.startsWith("192.168.1")) return window.open("https://neverland.nete.hu/", "_blank");
    router.push("/");
};

//import { useSocketdataStore } from "@/store/socketdataStore.mjs";
//const socket = useSocketdataStore();

import { useSupervisorGameStore } from "@/store/supervisorGameStore.mjs";
const supervisorGameStore = useSupervisorGameStore();
import timeFormat from "@/timeFormat.mjs";

const data = reactive({});

const click = (element) => {
    router.push(element.uri);
};

const getHosts = () => {
    const a = Array.from(new Set(Object.keys(supervisorGameStore.games).map((e) => supervisorGameStore.games[e].host)));
    if (a.includes(route.params.host)) return [route.params.host];
    return a;
};
const getRooms = (host) => {
    const rooms = {};
    for (const i in supervisorGameStore.games) if (supervisorGameStore.games[i].host === host) rooms[i] = supervisorGameStore.games[i];
    return rooms;
    //return supervisorGameStore.games;
};

const openRoom = (room) => {
    if (local) return (window.location.href = "https://" + room.host + ":" + room.port + "/operator/" + room.name);
    //router.push("/operator/" + room.name)
};

const getStatusOnline = (d) => {
    if (!d) return "";
    if (new Date().getTime() - new Date(d).getTime() < 5000) return "Online";
    return "OFFLINE";
};

const getCardColor = (ROOM) => {
    if (ROOM.GAME.HELPREQUEST) if (ROOM.GAME.TIME % 2 == 0) return "yellow";
    return ROOM.color;
};

onMounted(async () => {
    $socket.connect();
    await $socket.connection;
    supervisorGameStore.getUpdate();
});
//*/
</script>
<script>
export default {
    path: "/supervisor/:host?",
    name: "Neverland Supervisor",
    icon: "mdi-gamepad-variant",
    sort: 2100,
    section: "neverland",
};
</script>
<style scoped>
.scrollable {
    overflow-y: scroll;
    height: 100vh;
}
.black {
    background-color: #000;
}

.white {
    color: white;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
}
.time_display {
    font-size: 64px;
    font-family: var(--themefont), cursive;
    color: var(--v-text-base);
    /*background-color: rgba(0, 0, 0, 0.3);*/
}
.subtitle {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
