<template>
    {{ props }}
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="6">
                <v-autocomplete prepend-icon="mdi-database" label="Combobox" :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"></v-autocomplete>
            </v-col>

            <v-col cols="12" md="2"> </v-col>
        </v-row>
    </v-container>
</template>
<script setup>
import { ref, reactive, watch, nextTick } from "vue";
import { structuredClone as clone } from "../../helper-functions.mjs";

const emit = defineEmits(["update:modelValue", "update:valid", "update", "input"]);
const props = defineProps(["modelValue", "is", "class", "label", "clearable", "hint", "mandatory", "database_collection", "documents"]);

const data = reactive({
    database: null,
    collection: null,
});

const databaseItems = () => {
    if (!props.list) return [];
    return Object.keys(props.list);
};
const collectionItems = () => {
    const database = data.database;
    if (!database) return [];
    if (!props.list) return [];
    return Object.keys(props.list[database]);
};

const isValid = () => {
    if (!data.database) return "##&en Database selection ##&hu Adatbázis választás ##";
    if (!data.collection) return "##&en Database selection ##&hu Adatbázis választás ##";
    return true;
};

const databaseChange = () => {
    data.collection = null;
    updateHandler();
};
const collectionChange = () => {
    updateHandler();
};

const updateHandler = async () => {
    await nextTick();
    let datum = null;
    const database = data.database;
    const collection = data.collection;
    if (database && collection) datum = clone({ database, collection });
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};
</script>
