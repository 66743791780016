<template>
    <selector-component :structure="data.structure" @leftClickElement="leftClickElement" @rightClickElement="rightClickElement" />
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
const emit = defineEmits(["leftClickElement"]);

import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const data = reactive({
    structure: {},
    format: {},
    values: {},
    baseuri: "/base",
});

const leftClickElement = (element) => {
    router.push(element.uri);
};
const rightClickElement = (element) => {
    window.open(element.uri, "_blank");
};
onMounted(async () => {
    $socket.connect();
    await $socket.connection;

    $socket.emit("neverland", { run: "get_games" }, (e) => {
        //Ł(e);
        Object.assign(data, e);
    });
});
</script>

<script>
export default {
    path: "/design-game",
    name: "Neverland Game-designer",
    icon: "mdi-gamepad-square",
    root: "Home",
    section: "neverland",
    sort: 2000,
};
</script>
