<template>
    <div class="scrollable black">
        <div v-if="!socket.disconnected">
            <v-app-bar color="#003">
                <v-btn icon="mdi-home" @click="goHome()" color="white" />
                <v-btn icon="mdi-gamepad-variant" @click="router.push('/supervisor')" color="white" />
                <v-spacer />
                <!--v-btn v-for="(v, i) in getShortcuts()" :icon="v.icon" @click="router.push(v.uri)" :color="v.color" /-->
                <v-spacer />
                <ApplicationSnackbars />
            </v-app-bar>

            <SystemSnackbars />

            <div style="padding-top: 0px">
                <router-view></router-view>
            </div>
        </div>
        <div v-else>
            <v-alert
                density="compact"
                type="error"
                title="##&en Disconnected ##&hu Szétkapcsolva ##"
                text="##&en Connection to the application host is lost. ##&hu Az alkalmzásnak kapcsolata a kiszolgálójával megszakadt. ##"
            ></v-alert>
        </div>
    </div>
</template>

<script setup>
import LoginDialog from "@/LoginDialog.vue";

import SystemSnackbars from "@/application-components/SystemSnackbars.vue";
import ApplicationSnackbars from "@/application-components/ApplicationSnackbars.vue";

import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { useSocketdataStore } from "@/store/socketdataStore.mjs";
const socket = useSocketdataStore();

const goHome = () => {
    const hostname = ß.HOSTNAME;
    if (hostname.startsWith("192.168.1")) return window.open("https://neverland.nete.hu/", "_blank");
    router.push("/");
};

// <LanguageButton />
import LanguageListItem from "@/multilanguage-components/LanguageListItem.vue";

const drawer = ref(true);
const user = reactive({});

const getNavigation = () => {
    const nav = socket.data.navigation || {};

    const routes = router
        .getRoutes()
        .map((e) => e.meta)
        .filter((e) => nav[e.section] || e.section === true);

    return routes;
};
const getName = () => {
    if (!socket) return "";
    if (!socket.data) return "";
    if (!socket.data.user) return "";
    if (!socket.data.user.name) return "";
    return socket.data.user.name;
};

const data = reactive({
    structure: {},
    format: {},
    values: {},
    baseuri: "/base",
});

const click = (element) => {
    router.push(element.uri);
};

const getShortcuts = () => {
    let arr = [];
    //for (const game in data.structure) for (const item of data.structure[game]) arr.push({ color: "#AAF", ...item });
    return arr;
};

onMounted(async () => {
    $socket.connect();
    await $socket.connection;
    data.connected = true;

    $socket.emit("route-change", route.fullPath);

    $socket.on("redirect", (to) => {
        return router.push(to);
    });

    router.afterEach((to, from) => {
        $socket.emit("route-change", to.fullPath);
    });

    $socket.emit("neverland", { run: "get_games" }, (e) => {
        //Ł(e);
        Object.assign(data, e);
    });
});
//*/
</script>

<script>
export default {
    path: "/operator",
    name: "Neverland Operator",
    icon: "mdi-gamepad-variant",
    sort: 2100,
    //section: "neverland",
};
</script>
<style scoped>
.scrollable {
    overflow-y: scroll;
    height: 100vh;
}
.black {
    background-color: #000;
}
</style>
