<template>
    <v-snackbar color="background" timeout="-1" v-model="snackbar" location="top" multi-line>
        <div class="d-flex flex-row-reverse mb-6">
            <v-btn flat icon="mdi-close-circle" @click="snackbar = false"></v-btn>
        </div>

        <div v-for="(o, i) in messages" class="pa-1">
            <v-alert :color="o.color" :text="o.text"></v-alert>
            <v-divider></v-divider>
        </div>
    </v-snackbar>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
const snackbar = ref(false);

const messages = ref([]);

setInterval(() => {
    for (const x of messages.value) x.ttl--;
    let has = false;
    for (const x of messages.value) if (x.ttl > 0) has = true;
    if (has) return;
    messages.value.length = 0;
    snackbar.value = false;
}, 1000);

onMounted(async () => {
    await $connection();

    $socket.on("error", (text) => {
        snackbar.value = true;
        messages.value.push({
            text,
            color: "red",
            //title: "##&en Error ##&hu Hiba ##",
            ttl: 10,
        });
    });
    $socket.on("notice", (text) => {
        snackbar.value = true;
        messages.value.push({
            text,
            color: "orange",
            //title: "##&en Notice ##&hu Figyelmeztetés ##",
            ttl: 8,
        });
    });
    $socket.on("message", (text) => {
        snackbar.value = true;
        messages.value.push({
            text,
            color: "green",
            //title: "##&en Message ##&hu Értesítés ##",
            ttl: 6,
        });
    });
});
</script>
