<template>
    <div class="pa-16">
        <vuetifind-dialog :format="data.format" :values="document" @update="update" @clickEnter="addEventhandler" label="##&en Add new ##&hu Hozzáadása ##" />
        <selector-component :structure="data.structure" @leftClickElement="leftClickElement" @rightClickElement="rightClickElement" />
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
import VuetifindDialog from "@/vuetifind-components/VuetifindDialog.vue";

const router = useRouter();

const document = reactive({
    collection: "generic",
});

const data = reactive({
    structure: {},
    format: {},
});

import validators from "../validators.mjs";
const variableRule = (input) => {
    if (!validators.validateVariable(input)) return "Invalid";
    return true;
};

const dialogValid = () => {
    if (!dialog.collection) return "Collection";
    if (!dialog.callname) return "Callname";
    if (!validators.validateVariable(dialog.collection)) return "Collection invalid";
    if (!validators.validateVariable(dialog.callname)) return "Callname invalid";
    return true;
};

const getDatabases = () => {
    return Object.keys(data.databases);
};

const getCollections = (db) => {
    if (!db) return [];
    if (!data.databases[db]) return [];
    return Object.keys(data.databases[db]);
};

const leftClickElement = (e) => {
    router.push("/eventhandler/" + e.database + "/" + e.collection + "/" + e._id);
};
const rightClickElement = (e) => {
    window.open("/eventhandler/" + e.database + "/" + e.collection + "/" + e._id, "_blank");
};

const addEventhandler = (e) => {
    $socket.emit("add-eventhandler", document, ({ database, collection, _id }) => {
        router.push("/eventhandler/" + database + "/" + collection + "/" + _id);
    });
};

const update = (a) => {
    Object.assign(document, a);
};

onMounted(async () => {
    await $connection();
    $socket.removeListener("update-eventhandlers");
    $socket.on("update-eventhandlers", (o) => {
        Object.assign(data, o);
    });

    $socket.emit("update-eventhandlers");
});
</script>
<script>
export default {
    path: "/eventhandlers",
    name: "##&en Eventhandlers ##&hu Eseménykezelők ##",
    icon: "mdi-ballot",
    sort: 110,
    root: "Home",
    section: "eventhandlers",
};
</script>
