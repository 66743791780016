<template>
    <v-btn :disabled="props.readonly" color="primary" variant="elevated" class="ma-1" @click.native="importSheet" min-width="200">
        <p style="text-transform: none"><v-icon light>mdi-database-import</v-icon> Import</p></v-btn
    >
    <v-btn color="primary" variant="elevated" class="ma-1" @click.native="exportSheet" min-width="200">
        <p style="text-transform: none"><v-icon light>mdi-database-export</v-icon> Export</p></v-btn
    >
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["database", "collection", "query", "readonly"]);

const database = props.database;
const collection = props.collection;
const query = props.query;

const exportSheet = () => {
    $socket.emit("export-collection-xls", { database, collection, query }, (file) => {
        const link = document.createElement("a");
        link.href = "/database-export/" + file;
        link.download = file;
        link.click();
    });
};

const fileInput = document.createElement("input");
fileInput.type = "file";
fileInput.addEventListener("change", function () {
    const selectedFile = fileInput.files[0];
    const file = selectedFile;
    transfer = true;
    $socket.emit("import-collection-xls", { database, collection, file }, (o) => {
        //Object.assign(data, o);
        transfer = false;
    });
});

const importSheet = (event) => {
    fileInput.click();
};
</script>
