import { defineStore } from "pinia";
import { ref, computed, reactive } from "vue";

export const useEventhandlerStore = defineStore("eventhandler-store", () => {
    const eventhandler = reactive({});
    const format = reactive({});

    function assign(o) {
        Object.assign(eventhandler, o.eventhandler);
        Object.assign(format, o.format);
    }

    return { assign, eventhandler, format };
});
