<template>
    <div id="selector-content">
        <selector-component :structure="data.structure" @leftClickElement="leftClickElement" @rightClickElement="rightClickElement" />
    </div>
</template>

<script setup>
//*
import { ref, onMounted, reactive, computed, watch } from "vue";
import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const data = reactive({
    structure: {},
});

const leftClickElement = (e) => {
    router.push("/database/" + e.database + "/" + e.collection);
};
const rightClickElement = (e) => {
    window.open("/database/" + e.database + "/" + e.collection, "_blank");
};
onMounted(async () => {
    await $connection();

    $socket.removeListener("update-database");

    $socket.on("update-database", (o) => {
        Object.assign(data, o);
    });

    $socket.emit("update-database");
});
//*/
</script>
<script>
export default {
    path: "/database",
    name: "##&en Databases ##&hu Adatbázisok ##",
    icon: "mdi-database",
    sort: 100,
    root: "Home",
    section: "database",
};
</script>
<style scoped>
#selector-content {
    padding: 24px;
    padding-bottom: 100px;
}
</style>
