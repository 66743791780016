<template>
    <div :class="props.classes || 'text-h7 pa-2 ml-2'">
        {{ props.label }}
        <pre v-if="props.text !== undefined">{{ props.text }}</pre>
        <pre v-if="props.modelValue !== undefined">{{ props.modelValue }}</pre>
        <v-btn v-if="props.link" class="ma-3" :href="props.link" :target="props.target || '_blank'"
            ><v-icon> {{ props.icon || "mdi-open-in-new" }} </v-icon>
            <pre> {{ props.linkTitle || props.link }} </pre>
        </v-btn>
    </div>
</template>
<script setup>
// static text
const props = defineProps(["label", "text", "modelValue", "classes", "icon", "link", "target", "linkTitle"]);
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-pretext",
};
</script>
