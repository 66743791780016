<template>
    <v-card v-if="data.error"><v-alert type="error" :title="data.error" icon="mdi-alert-circle" variant="tonal"></v-alert></v-card>
    <v-card v-resize="onResize" class="pa-4 scrollable overflow-auto">
        <v-card-item class="pa-10">
            <v-container fluid>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="8">
                        <document-info :database="database" :collection="collection" :id="id" :data="data" />
                    </v-col>
                </v-row>
            </v-container>
        </v-card-item>
        <v-container fluid color="rgba(0,0,0,0)">
            <v-btn
                :disabled="!data.host || data.host === hostname"
                :color="!data.host || data.host === hostname ? 'gray' : 'primary'"
                @click="openHostDocument()"
                color="primary"
                variant="elevated"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon dark>mdi-database</v-icon> Server</p>
            </v-btn>
            <v-btn @click="openCollection()" color="primary" variant="elevated" min-width="200" class="ma-1">
                <p style="text-transform: none"><v-icon dark>mdi-database</v-icon>##&en Collection ##&hu Kollekció ##</p>
            </v-btn>
            <v-btn
                @click.native="saveDocument()"
                :disabled="data.valid !== true || data.document._readonly === true"
                :color="data.valid !== true || data.document._readonly === true ? 'gray' : 'primary'"
                variant="elevated"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon>mdi-database-check</v-icon> ##&en Save ##&hu Mentés ##</p>
            </v-btn>
            <v-btn
                @click="removeDocument()"
                :disabled="data.readonly || !data.document._createdAt || data.document._readonly"
                :color="getRemoveColor()"
                variant="elevated"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon dark>mdi-delete</v-icon> ##&en Delete ##&hu Törlés ##</p>
            </v-btn>
            <v-btn @click.native="openDocument(data.prev)" :disabled="!data.prev" :color="!data.prev ? 'gray' : 'primary'" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon>mdi-chevron-left</v-icon> ##&en Previous ##&hu Előző ##</p>
            </v-btn>
            <v-btn @click.native="openDocument(data.next)" :disabled="!data.next" :color="!data.next ? 'gray' : 'primary'" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon>mdi-chevron-right</v-icon> ##&en Next ##&hu Következő ##</p>
            </v-btn>
        </v-container>
        <v-card-text>
            <vuetiform-poliform
                :bond="bond"
                :format="data.format"
                v-model="data.document"
                v-model:valid="data.valid"
                :key="data.document._id"
                :disabled="data.readonly || data.document._readonly"
            />
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn icon size="large" @click.native="data.debug = !data.debug" color="blue">
                <v-icon>mdi-triangle-small-down</v-icon>
            </v-btn>
            <v-spacer />
        </v-card-actions>
        <pre v-if="data.debug">{{ data }}</pre>
    </v-card>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
const props = defineProps(["bond", "database", "collection", "id", "redirecting", "format"]);

const database = props.database;
const collection = props.collection;
const id = props.id;
const bond = { database, collection, id, page: "document" };
const hostname = (ß.HOSTNAME || "localhost").split(".")[0];

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import DocumentInfo from "@/database-components/DocumentInfo.vue";
import VuetiformPoliform from "@/vuetiform/VuetiformPoliform.vue";
import HashtagsCombobox from "@/vuetiform-components/HashtagsCombobox.vue";

const openDocument = (id) => {
    router.push("/document/" + database + "/" + collection + "/" + id);
};

const openCollection = () => {
    router.push("/database/" + database + "/" + collection);
};
const openHostDocument = () => {
    return (window.location.href = "https://" + data.host + "/document/" + database + "/" + collection + "/" + data.document._id);
};

const data = reactive({
    database,
    collection,
    document: {},
    format: props.format || {},
    valid: true,
    readonly: true,
    host: null,
    debug: false,
    createdBy: null,
    updatedBy: null,
    hashtags: null,
    error: null,
    prev: null,
    next: null,
    removal: false,
});

const saveDocument = () => {
    data.document._database = database;
    data.document._collection = collection;
    const document = { _id: id, ...data.document };
    $socket.emit("save-document", { database, collection, document }, ({ uri }) => {
        if (uri) return router.push(uri);
        if (props.redirecting) router.push("/database/" + database + "/" + collection);
    });
};

const getRemoveColor = () => {
    if (data.readonly || !data.document._createdAt || data.document._readonly) return "gray";
    if (data.removal) return "red";
    return "primary";
};

const removeDocument = () => {
    if (!data.removal) {
        data.removal = true;
        setTimeout(() => {
            data.removal = false;
        }, 2000);
        return;
    }
    const document = data.document;
    $socket.emit("remove-document", { database, collection, document }, ({ uri }) => {
        if (uri) return router.push(uri);
        if (props.redirecting) router.push("/database/" + database + "/" + collection);
    });
};

const resizedHeight = ref(0);
const onResize = () => {
    //resizedHeight.value = window.innerHeight - 10;
};

const update = () => {
    $socket.emit("update-document", { database, collection, id }, (o) => {
        if (o.database !== database) return;
        if (o.collection !== collection) return;
        if (o.id !== id) return;
        Object.assign(data.document, o.document);
        Object.assign(data.format, o.format);
        data.readonly = o.readonly;
        data.host = o.host;
        data.createdBy = o.createdBy;
        data.updatedBy = o.updatedBy;
        data.hashtags = o.hashtags || [];
        data.next = o.next;
        data.prev = o.prev;
    });
};

onMounted(async () => {
    await $connection();
    update();
});
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
/* Scrollable in a dialog*/
.scrollable {
    /*overflow-y: scroll;*/
    height: 100%;
}
</style>
