<template>
    {{ route.params.kiosk }} KIOSK
    <pre class="time_display" v-html="timeFormat(GAME.TIME)"></pre>
    <v-btn class="ma-2" fab color="primary" @click="ingame('helprequest')" title="clear"><v-icon color="text">mdi-lifebuoy</v-icon></v-btn>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["game"]);
import timeFormat from "@/timeFormat.mjs";
import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();
const GAME = gameStore.getGame(route.params.game);
const game = route.params.game;

const ingame = (run) => {
    $socket.emit("ingame", { game, run });
};

onMounted(async () => {
    $socket.connect();
    await $socket.connection;

    //$socket.emit("operator", { game, run: "update" });
    ingame("update");

    $socket.emit("route-change", route.fullPath);

    $socket.on("redirect", (to) => {
        return router.push(to);
    });

    router.afterEach((to, from) => {
        $socket.emit("route-change", to.fullPath);
    });
});
</script>

<script>
export default {
    path: "/kiosk/:game/:kiosk",
    name: "Kiosk",
    icon: "mdi-piggy-bank-outline",
    //root: "Home",
    //section: "neverland",
};
</script>
