<template>
    <div id="operator" class="fill-height">
        <OperatorClock :game="game" />
        <OperatorGameManager :game="game" />

        <div id="textBlock">
            <div class="textContainer textEffect">
                <span style="color: yellow; font-size: calc(30px + 2vmin)">{{ (GAME.OBJECT || {}).name }}</span>
            </div>
            <div class="ma-6 textContainer textEffect">
                <span style="color: white; font-size: calc(20px + 2vmin)">{{ GAME.SUBTITLE || "-" }}</span>
            </div>
        </div>
        <OperatorHistory :game="game" />
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import OperatorClock from "@/OperatorClock.vue";
import OperatorGameManager from "@/OperatorGameManager.vue";
import OperatorHistory from "@/OperatorHistory.vue";

const game = "asylum";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();
const GAME = gameStore.getGame(game);

onMounted(async () => {
    $socket.connect();
    await $socket.connection;
    gameStore.getUpdate(game);
});
</script>

<script>
export default {
    path: "/operator/asylum",
    name: "Operator",
    icon: "mdi-piggy-bank",
    root: "Operator",
    //section: "neverland",
};
</script>
<style scoped>
#operator {
    background: url("@/asylum-media/operator-background.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top: 70px;
}
#textBlock {
    min-height: 300px;
    padding: 10px;
    background: #000c;
    text-align: center;
    margin-left: 5vw;
    width: 90vw;
}
.textContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    line-height: 0.8;
    word-break: break-word;
}
.textEffect {
    color: #ffffff;
    font-family: "VT323", monospace;
    font-size: 48px;
    text-shadow: 0 0 5px #fffd78, 2px 2px 8px #000000, 6px 6px 18px #220000, -2px -2px 3px #005500, -3px -2px 8px #000000;
}
.textContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    line-height: 0.8;
}
#flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 100vh;
}
</style>
