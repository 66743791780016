<template>
    <v-text-field
        class="pa-3"
        prepend-icon="mdi-progress-clock"
        @click:prepend="dialog = true"
        @update:focused="dialog = true"
        :value="(props.label || '') + ' ' + getDurationText(data)"
        :disabled="props.disabled"
        :hint="props.hint"
    />
    <v-row justify="center">
        <v-dialog v-model="dialog" width="1024">
            <v-card>
                <v-card-title class="d-flex pt-10 pl-10">
                    {{ props.label || "##&en Set Duration ##&hu Időhossz ##" }}
                    <v-btn id="close" flat type="icon" @click="dialog = false" icon="mdi-close" class="ml-auto"></v-btn>
                </v-card-title>
                <v-card-subtitle class="d-flex pl-10">
                    {{ props.hint }}
                </v-card-subtitle>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" v-if="options.day">
                                <v-combobox
                                    label="##&en Days ##&hu Napok ##"
                                    :items="[0, 1, 5, 7, 14, 30]"
                                    required
                                    v-model="data.days"
                                    prepend-icon="mdi-minus-box"
                                    @click:prepend="daysMinus()"
                                    append-icon="mdi-plus-box"
                                    @click:append="daysPlus()"
                                    :rules="[validateDays]"
                                    type="number"
                                    min="0"
                                    max="365000"
                                ></v-combobox>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" v-if="options.hour">
                                <v-autocomplete
                                    label="##&en Hour ##&hu Óra ##"
                                    :items="Array.from({ length: 24 }, (_, i) => i)"
                                    required
                                    v-model="data.hours"
                                    prepend-icon="mdi-minus-box"
                                    @click:prepend="hourMinus()"
                                    append-icon="mdi-plus-box"
                                    @click:append="hourPlus()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" v-if="options.minute">
                                <v-autocomplete
                                    label="##&en Minute ##&hu Perc ##"
                                    :items="Array.from({ length: 60 }, (_, i) => i)"
                                    required
                                    v-model="data.minutes"
                                    prepend-icon="mdi-minus-box"
                                    @click:prepend="minuteMinus()"
                                    append-icon="mdi-plus-box"
                                    @click:append="minutePlus()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" v-if="options.second">
                                <v-autocomplete
                                    label="##&en Second ##&hu Másodperc ##"
                                    :items="Array.from({ length: 60 }, (_, i) => i)"
                                    required
                                    v-model="data.seconds"
                                    prepend-icon="mdi-minus-box"
                                    @click:prepend="secondMinus()"
                                    append-icon="mdi-plus-box"
                                    @click:append="secondPlus()"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <pre>{{ getDurationText(data) }}</pre>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="
                            updateHandler();
                            dialog = false;
                        "
                        icon="mdi-clock-time-two-outline"
                        size="x-large"
                        color="primary"
                        :disabled="!isValid()"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script setup>
import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
import validators from "../../validators.mjs";

const dialog = ref(false);

function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["fields", "modelValue", "disabled", "label", "hint", "options"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

let duration = clone(props.modelValue || 0);
const data = reactive({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
});

const options = props.options || {
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
};

Ł(options, props.options);

function splitDuration(seconds) {
    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return { days, hours, minutes, seconds };
}

function sumDuration({ days, hours, minutes, seconds }) {
    return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
}

if (duration > 0) Object.assign(data, splitDuration(duration));

function validateDays(input) {
    if (!validators.validatePositiveInteger(input)) return "Invalid";
    return true;
}

function getDurationText(data) {
    const texts = [];
    if (data.days > 0) texts.push(data.days + " ##&en Days ##&hu Nap ##");
    if (data.hours > 0) texts.push(data.hours + " ##&en Hours ##&hu Óra ##");
    if (data.minutes > 0) texts.push(data.minutes + " ##&en Minutes ##&hu Perc ##");
    if (data.seconds > 0) texts.push(data.seconds + " ##&en Seconds ##&hu Másodperc ##");
    return texts.join(", ");
}

// validators
const valid = reactive({});
const isValid = () => {
    return sumDuration(data) >= 0;
};

function daysPlus() {
    data.days++;
}

function daysMinus() {
    data.days--;
    if (data.days >= 0) return;
    data.days = 0;
}

function hourPlus() {
    data.hours++;
    if (data.hours < 24) return;
    data.hours = 23;
}

function hourMinus() {
    data.hours--;
    if (data.hours >= 0) return;
    data.hours = 0;
}
function minutePlus() {
    data.minutes++;
    if (data.minutes < 60) return;
    data.minutes = 59;
}

function minuteMinus() {
    data.minutes--;
    if (data.minutes >= 0) return;
    data.minutes = 0;
}

function secondPlus() {
    data.seconds++;
    if (data.seconds < 1000) return;
    data.seconds = 999;
}

function secondMinus() {
    data.seconds--;
    if (data.seconds >= 0) return;
    data.seconds = 0;
}

const updateHandler = async (from) => {
    await nextTick();
    const datum = sumDuration(data);
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};

//updateHandler();
emit("update:valid", isValid());

onMounted(async () => {
    // maybe
    emit("update:valid", isValid());
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-duration",
};
</script>
<style scoped></style>
