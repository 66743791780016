<template>
    <div :class="{ invalid: isValid() !== true, readonly: format.readonly === true }">
        <component
            :is="format.is"
            :bond="props.bond"
            v-bind="format"
            v-model="data.model"
            @input="updateHandler()"
            @update:modelValue="updateHandler()"
            @update:valid="validHandler"
            class="pa-3"
        >
        </component>
    </div>
    <!-- <v-alert v-if="isValid() !== true" color="warning" max-height="1"></v-alert> -->
    <!-- 
  	component {{ format }} value: {{ modelValue }} data: {{ data }}
    end -->
</template>

<script setup>
// this components goal is to merge vuetify and vuetiform components
import { ref, reactive, watch, nextTick, toRaw } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["bond", "format", "modelValue", "disabled"]);

// allow manipulationg the format
const format = clone(props.format);
if (props.disabled) format.disabled = true;
//if (format.readonly) format.disabled = true;
if (format.readonly) format.readonly = true;

// we should be reactive
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);
const data = reactive({ model: clone(props.modelValue) });

// process vuetify validators
import validators from "../../validators.mjs";
if (format.validators || format.regex) {
    format.rules = [];
    let list = format.validators || [];
    if (typeof format.validators === "string") list = format.validators.split("|");
    if (format.mandatory === true) list.push("validateMandatoryField");
    for (const i of [...new Set(list)])
        format.rules.push((input) => {
            const r = validators[i](input);
            if (r === true) return true;
            if (r === false) return "Invalid by " + i;
            return r;
        });
    if (format.regex)
        format.rules.push((str) => {
            const re = new RegExp(format.regex, "i");
            if (re.test(str) == false) return "##&en Regex check failed. ##&hu regex ellenőrzés hibajelzés ##";
            return true;
        });
}

const isValid = () => {
    if (format.readonly === true) return true;
    if (format.mandatory === true) if (data.model === undefined || data.model === null) return "##&en Mandatory field ##&hu Kötelező mező ##";
    if (!format.validators) return true;
    let list = format.validators || [];
    if (typeof format.validators === "string") list = format.validators.split("|");
    for (const i of list) {
        //Ł(i, data.model, validators[i](data.model));
        const r = validators[i](data.model);
        if (r === false) return "Invalid from " + i;
        if (r !== true) return r;
    }
    return true;
};

// we also need to keep track of validators of poliform modules
//const valid = ref(isValid() === true);

const type = (format._type || "String").toLowerCase();
const numeric = (i) => {
    if (i === undefined) return null;
    const int = parseInt(i.toString().replace(/\s+/g, ""), 10);
    const num = Number(int);
    if (isNaN(num)) return null;
    return num;
};

const updateHandler = async () => {
    await nextTick();
    let datum = clone(data.model);
    if (type === "Number") datum = numeric(datum);

    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};

const validHandler = (b) => {
    updateHandler();
};

// default value
if (format.default !== undefined) {
    if (data.model === undefined) {
        data.model = format.default;
        updateHandler();
    }
}

updateHandler();
</script>

<script>
import * as vuetify from "vuetify/components";
import vuetiform from "../../vuetiform-components.mjs";
// allow recursive

export default {
    inheritAttrs: false,
    components: { ...vuetify, ...vuetiform },
    name: "vuetiform-component",
};
</script>
<style scoped>
.invalid {
    background-color: rgba(128, 0, 0, 0.02);
    border-left: 3px solid red;
}
.readonly {
    background-color: rgba(0, 0, 128, 0.06);
    border-left: 3px solid blue;
}
</style>
