<template>
    <div v-if="props.filename" class="ma-2 pa-4">
        <v-img v-if="data.image" :src="data.image" cover></v-img>

        <a v-if="data.uripath" :href="data.uripath" target="_blank">{{ filename }}</a>
        <p v-else>{{ filename }}</p>
    </div>
</template>

<script setup>
import { onMounted, reactive } from "vue";

const props = defineProps(["bond", "module", "channel", "subpath", "filename", "modelValue"]);

const data = reactive({
    image: null,
    uripath: null,
});

function testImageSupport(blob) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const objectURL = URL.createObjectURL(blob);

        img.onload = () => {
            URL.revokeObjectURL(objectURL);
            resolve(true);
        };
        img.onerror = () => {
            URL.revokeObjectURL(objectURL);
            resolve(false);
        };

        img.src = objectURL;
    });
}

onMounted(async () => {
    Ł("MediaDisplayComponent mounted", props);
    await $connection();
    const eventName = (props.module || "vuetifiles") + ":" + (props.channel || "getContent");
    const bond = props.bond;
    const subpath = props.subpath;
    const filename = props.modelValue || props.filename;
    const port = window.location.port;

    if (filename)
        $socket.emit(eventName, { bond, subpath, filename, port }, async ({ file, type, uripath }) => {
            data.uripath = uripath;
            // loading file directly over socket.io
            if (file) {
                const blob = new Blob([file], { type });
                const isImage = await testImageSupport(blob);
                if (isImage) data.image = URL.createObjectURL(blob);
                return;
            }
            // loading file over a get request
            if (type) if (type.split("/")[0] === "image") data.image = uripath;

            Ł(filename, file, type, uripath);
        });
});
</script>
