<template>
    <v-snackbar id="snack" v-model="snackbar" timeout="-1" multi-line @click="snackbar = false">
        {{ messages }}
    </v-snackbar>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
const snackbar = ref(false);
let ttl = 0;
const messages = ref([]);

setInterval(() => {
    ttl--;
    if (ttl > 0) return;
    snackbar.value = false;
}, 1000);

onMounted(async () => {
    await $connection();

    $socket.on("notification", (text) => {
        snackbar.value = true;
        messages.value = text;
        ttl = 3;
    });
});
</script>
<style scoped>
#snack:hover {
    cursor: pointer;
}
</style>
