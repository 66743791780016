<template>
    <div class="center scrollable">
        <v-card v-for="o in GAME.HISTORY" min-width="600" max-width="600" min-height="50" max-height="50" color="#0007" class="ma-1">
            <v-card-text>
                <span class="displayText">{{ timeFormat(o.at) }} - {{ o.name }} </span>
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup>
const props = defineProps(["game"]);

import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();

const GAME = gameStore.getGame(props.game);
const game = props.game;

import timeFormat from "@/timeFormat.mjs";
</script>

<style scoped>
.displayText {
    color: white;
    white-space: pre;
    font-size: 20px;
}
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}
.scrollable {
    overflow-y: scroll;
    height: 40vh;
}
</style>
