export default {
    accelerationX: {
        min: -2000,
        max: 2000,
        group: "acceleration",
        unit: "mG",
        color: "#088",
    },
    accelerationY: {
        min: -2000,
        max: 2000,
        group: "acceleration",
        unit: "mG",
        color: "#808",
    },
    accelerationZ: {
        min: -2000,
        max: 2000,
        group: "acceleration",
        unit: "mG",
        color: "#880",
    },
    temperature: {
        min: -20,
        max: 60,
        group: "temperature",
        unit: "°C",
        color: "#F64",
    },
    batteryPercentage: {
        min: 0,
        max: 100,
        group: "battery",
        unit: "%",
        color: "#46F",
    },
    rssi: {
        min: -100,
        max: 0,
        group: "signal",
        unit: "db",
        color: "#303",
    },
    light: {
        min: 0,
        max: 1,
        group: "light",
        unit: "lux",
        color: "#161",
    },
};
