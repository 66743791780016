<template>
    <v-container class="pt-10">
        <v-row justify="space-around">
            <LoginCard @complete="completeHandler()" :autologin="false" />
        </v-row>
    </v-container>
</template>

<script setup>
import LoginCard from "@/LoginCard.vue";
import { useRouter, useRoute } from "vue-router";
import { ref } from "vue";
const router = useRouter();

import { useSessionStore } from "@/store/sessionStore.mjs";
const sessionStore = useSessionStore();

const completeHandler = async () => {
    //await sessionStore.resetSession();
    await sessionStore.fetchSession();
    router.push("/");
};
</script>

<script>
export default {
    path: "/account",
    name: "$getName", // display user name in left sidebar
    icon: "mdi-account",
    sort: 10,
    section: true,
};
</script>
