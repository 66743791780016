import { defineStore } from "pinia";
import { ref, computed, reactive } from "vue";

export const useQueryStore = defineStore("query-store", () => {
    const query = reactive({});
    const format = reactive({});

    function assign(o) {
        Object.assign(query, o.query);
        Object.assign(format, o.format);
    }

    return { assign, query, format };
});
