<template>
    <v-text-field
        class="pa-3"
        prepend-icon="mdi-calendar"
        @click:prepend="dialog = true"
        @update:focused="dialog = true"
        :value="(props.label || '') + ' ' + displayDate(props.modelValue)"
        :disabled="props.disabled"
        :hint="props.hint"
    />
    <v-row justify="center">
        <v-dialog v-model="dialog" width="1024">
            <v-card>
                <v-card-title class="d-flex pt-10 pl-10">
                    {{ props.label || "##&en Set Date with time ##&hu Dátum és idő megadása ##" }}
                    <v-btn id="close" flat type="icon" @click="dialog = false" icon="mdi-close" class="ml-auto"></v-btn>
                </v-card-title>
                <v-card-subtitle class="d-flex pl-10">
                    {{ props.hint }}
                </v-card-subtitle>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" v-if="options.year">
                                <v-combobox
                                    label="##&en Year ##&hu Év ##"
                                    :items="Array.from({ length: 20 }, (_, i) => i + 2020)"
                                    required
                                    v-model="data.year"
                                    prepend-icon="mdi-minus-box"
                                    @click:prepend="data.year--"
                                    append-icon="mdi-plus-box"
                                    @click:append="data.year++"
                                    :rules="[validateYear]"
                                    type="number"
                                    min="1900"
                                    max="2200"
                                ></v-combobox>
                            </v-col>
                            <v-col cols="12" v-if="options.month">
                                <v-autocomplete
                                    label="##&en Month ##&hu Hónap ##"
                                    :items="months"
                                    required
                                    v-model="data.month"
                                    prepend-icon="mdi-minus-box"
                                    @click:prepend="monthMinus()"
                                    append-icon="mdi-plus-box"
                                    @click:append="monthPlus()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" v-if="options.day">
                                <table align="center">
                                    <thead>
                                        <tr>
                                            <th>##&en Monday ##&hu Hétfő ##</th>
                                            <th>##&en Tuesday ##&hu Kedd ##</th>
                                            <th>##&en Wednesday ##&hu Szerda ##</th>
                                            <th>##&en Thursday ##&hu Csütörtök ##</th>
                                            <th>##&en Friday ##&hu Péntek ##</th>
                                            <th>##&en Saturday ##&hu Szombat ##</th>
                                            <th>##&en Sunday ##&hu Vasárnap ##</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="w in getDayMatrix(data.year, data.month)">
                                            <td
                                                v-for="i in [0, 1, 2, 3, 4, 5, 6]"
                                                :class="{ 'current-month': w[i].current, 'selected-day': w[i].selected }"
                                                @click="setMonthDate(w[i].date)"
                                                class="day-cell"
                                            >
                                                {{ w[i].day }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </v-col>
                            <v-col v-if="options.hour" cols="12" sm="6" md="4">
                                <v-autocomplete
                                    label="##&en Hour ##&hu Óra ##"
                                    :items="Array.from({ length: 24 }, (_, i) => i)"
                                    required
                                    v-model="data.hour"
                                    prepend-icon="mdi-minus-box"
                                    @click:prepend="hourMinus()"
                                    append-icon="mdi-plus-box"
                                    @click:append="hourPlus()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="options.minute" cols="12" sm="6" md="4">
                                <v-autocomplete
                                    label="##&en Minute ##&hu Perc ##"
                                    :items="Array.from({ length: 60 }, (_, i) => i)"
                                    required
                                    v-model="data.minute"
                                    prepend-icon="mdi-minus-box"
                                    @click:prepend="minuteMinus()"
                                    append-icon="mdi-plus-box"
                                    @click:append="minutePlus()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="options.second" cols="12" sm="6" md="4">
                                <v-autocomplete
                                    label="##&en Second ##&hu Másodperc ##"
                                    :items="Array.from({ length: 60 }, (_, i) => i)"
                                    required
                                    v-model="data.second"
                                    prepend-icon="mdi-minus-box"
                                    @click:prepend="secondMinus()"
                                    append-icon="mdi-plus-box"
                                    @click:append="secondPlus()"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn icon="mdi-clock" size="x-large" color="primary" @click="setNow()"></v-btn>
                    <v-spacer></v-spacer>
                    <pre>{{ displayDate(new Date(data.year, data.month, data.date, data.hour, data.minute, data.second)) }}</pre>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="
                            updateHandler();
                            dialog = false;
                        "
                        icon="mdi-calendar"
                        size="x-large"
                        color="primary"
                        :disabled="!isValid()"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script setup>
import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
import validators from "../../validators.mjs";

const dialog = ref(false);

function clone(p) {
    return structuredClone(toRaw(p));
}

// fields: YYYY

const props = defineProps(["format", "modelValue", "disabled", "label", "hint", "default", "options"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

let date = new Date(clone(props.modelValue || props.default));
if (!date instanceof Date || isNaN(date)) date = new Date();

if (props.notime) date.setHours(0, 0, 0, 0);

const data = reactive({
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
    millisecond: 0,
});

function setNow() {
    Object.assign(data, {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        date: new Date().getDate(),
        hour: new Date().getHours(),
        minute: new Date().getMinutes(),
        second: new Date().getSeconds(),
        millisecond: 0,
    });
}

function getWeekdayName(date) {
    let w = date.getDay();

    if (w === 0) return "##&en Sunday ##&hu Vasárnap ##";
    if (w === 1) return "##&en Monday ##&hu Hétfő ##";
    if (w === 2) return "##&en Tuesday ##&hu Kedd ##";
    if (w === 3) return "##&en Wensday ##&hu Szerda ##";
    if (w === 4) return "##&en Thursday ##&hu Csütörtök ##";
    if (w === 5) return "##&en Friday ##&hu Péntek ##";
    if (w === 6) return "##&en Saturday ##&hu Szombat ##";
    return "?";
}

const options = props.options || {
    year: "numeric",
    month: "long",
    day: "2-digit",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
};

function displayDate(date) {
    if (!date) date = new Date();
    if (typeof date !== "object") date = new Date(date);

    const formatter = new Intl.DateTimeFormat("##&en en-US ##&hu hu-HU ##", options);
    return formatter.format(date);
}

const months = [
    { title: "01. ##&en January ##&hu Január ##", value: 0 },
    { title: "02. ##&en February ##&hu Február ##", value: 1 },
    { title: "03. ##&en March ##&hu Március ##", value: 2 },
    { title: "04. ##&en April ##&hu április ##", value: 3 },
    { title: "05. ##&en May ##&hu Május ##", value: 4 },
    { title: "06. ##&en June ##&hu Június ##", value: 5 },
    { title: "07. ##&en July ##&hu Július ##", value: 6 },
    { title: "08. ##&en August ##&hu Augusztus ##", value: 7 },
    { title: "09. ##&en September ##&hu Szeptember ##", value: 8 },
    { title: "10. ##&en October ##&hu Október ##", value: 9 },
    { title: "11. ##&en November ##&hu November ##", value: 10 },
    { title: "12. ##&en December ##&hu December ##", value: 11 },
];

function monthPlus() {
    data.month++;
    if (data.month <= 11) return;
    data.month = 0;
    data.year++;
}

function monthMinus() {
    data.month--;
    if (data.month >= 0) return;
    data.month = 11;
    data.year--;
}

function setMonthDate(d) {
    data.month = d.getMonth();
    data.year = d.getFullYear();
    data.date = d.getDate();
}

function hourPlus() {
    data.hour++;
    if (data.hour < 24) return;
    data.hour = 23;
}

function hourMinus() {
    data.hour--;
    if (data.hour >= 0) return;
    data.hour = 0;
}
function minutePlus() {
    data.minute++;
    if (data.minute < 60) return;
    data.minute = 59;
}

function minuteMinus() {
    data.minute--;
    if (data.minute >= 0) return;
    data.minute = 0;
}

function secondPlus() {
    data.second++;
    if (data.second < 1000) return;
    data.second = 999;
}

function secondMinus() {
    data.second--;
    if (data.second >= 0) return;
    data.second = 0;
}

function validateYear(input) {
    if (!validators.validateYear(input)) return "Invalid";
    return true;
}

// 2018 april
// 2018 december
function getDayMatrix(year, month) {
    const dayMatrix = [];
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    let dayPointer = new Date(firstDayOfMonth);
    let offset = 0;
    const day = dayPointer.getDay();
    if (day === 0) dayPointer.setDate(dayPointer.getDate() - dayPointer.getDay() + 1 - 7); // Set to the first Monday of the matrix
    else dayPointer.setDate(dayPointer.getDate() - dayPointer.getDay() + 1);

    for (let week = 0; week < 6; week++) {
        const weekDays = [];

        for (let day = 0; day < 7; day++) {
            const o = { day: dayPointer.getDate(), date: new Date(dayPointer), current: dayPointer.getMonth() === month };
            if (dayPointer.getFullYear() === data.year && dayPointer.getMonth() === data.month && dayPointer.getDate() === data.date) o.selected = true;
            weekDays.push(o);
            dayPointer.setDate(dayPointer.getDate() + 1);
        }

        dayMatrix.push(weekDays);
    }

    return dayMatrix;
}

// validators
const valid = reactive({});
const isValid = () => {
    new Date(data.year, data.month, data.date, data.hour, data.minute, data.second);
    return date instanceof Date && !isNaN(date);
};

const updateHandler = async (from) => {
    await nextTick();
    const datum = new Date(data.year, data.month, data.date, data.hour, data.minute, data.second);
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};
//updateHandler();
emit("update:valid", isValid());

onMounted(async () => {
    // maybe
    emit("update:valid", isValid());
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-date",
};
</script>
<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}
th,
td {
    border: 1px solid #ccc;
    /* Main size defining parameter*/
    padding: 12px;
    text-align: center;
}
th {
    background-color: rgb(var(--v-theme-background));
    width: 14.28%;
    text-align: left;
}
.current-month {
    background-color: rgb(var(--v-theme-info));
}
.selected-day {
    background-color: rgb(var(--v-theme-success)) !important;
}
.day-cell {
    cursor: default;
}
.day-cell:hover {
    background-color: rgb(var(--v-theme-warning));
}
</style>
