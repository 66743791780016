// an advanced version of deepCopy. Things that structuredClone can not digest will be converted to #strings.
export default function clone(i) {
    if (Object.prototype.toString.call(i) === "[object String]") return i;
    if (Object.prototype.toString.call(i) === "[object Number]") return i;

    if (Object.prototype.toString.call(i) === "[object Boolean]") return i;
    if (Object.prototype.toString.call(i) === "[object Null]") return null;
    if (Object.prototype.toString.call(i) === "[object Undefined]") return undefined;

    if (Object.prototype.toString.call(i) === "[object Array]") {
        return i.map((e) => clone(e));
    }
    if (Object.prototype.toString.call(i) === "[object Object]") {
        const copy = {};
        for (const attr in i) if (Object.prototype.hasOwnProperty.call(i, attr)) copy[attr] = clone(i[attr]);
        return copy;
    }

    if (Object.prototype.toString.call(i) === "[object Date]") return new Date(i.getTime());
    if (Object.prototype.toString.call(i) === "[object AsyncFunction]") return "#[AsyncFunction " + i.name + "]";
    if (Object.prototype.toString.call(i) === "[object Function]") return "#[Function " + i.name + "]";
    if (Object.prototype.toString.call(i) === "[object RegExp]") return new RegExp(i.source, i.flags);

    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm#supported_types
    if (Object.prototype.toString.call(i) === "[object Map]") {
        const newMap = new Map();
        for (const [key, value] of i) newMap.set(clone(key), clone(value));
        return newMap;
    }
    if (Object.prototype.toString.call(i) === "[object Set]") {
        const newSet = new Set();
        for (const item of i) newSet.add(clone(item));
        return newSet;
    }

    // what the type is this then?
    return "#" + Object.prototype.toString.call(i);
}
