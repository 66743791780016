<template>
    <v-card :color="get_toolbar_color()" class="white center">
        <pre class="time_display" v-html="timeFormat(GAME.TIME) + ' | ' + timeFormat(GAME.SINCE)"></pre>
    </v-card>
    <v-card :color="get_toolbar_color()" class="white center">
        <v-btn class="ma-2" fab color="primary" @click="nextLang()">{{ GAME.LANG }}..</v-btn>
        <v-btn class="ma-2" fab color="primary" @click="ingame('new')" title="init game"><v-icon color="text">mdi-skip-previous</v-icon></v-btn>
        <v-btn class="ma-2" fab color="primary" @click="ingame('game_on')" title="game on"><v-icon color="text">mdi-play</v-icon></v-btn>
        <v-btn class="ma-2" fab color="primary" @click="ingame('game_hold')" title="game hold"><v-icon color="text">mdi-pause</v-icon></v-btn>
        <v-btn class="ma-2" fab color="primary" @click="ingame('clear')" title="clear"><v-icon color="text">mdi-close</v-icon></v-btn>
        <v-btn class="ma-2" fab color="primary" @click="ingame('game_add_one_minute')" title="+1 minute"><v-icon color="text">mdi-clock-plus</v-icon></v-btn>
        <v-btn class="ma-2" fab color="primary" @click="ingame('game_substract_one_minute')" title="-1 minute"><v-icon color="text">mdi-clock-minus</v-icon></v-btn>
        <v-btn class="ma-2" fab color="primary" @click="ingame('game_won')" title="game won"><v-icon color="text">mdi-trophy</v-icon></v-btn>
        <v-btn class="ma-2" fab color="primary" @click="ingame('game_lost')" title="game lost"><v-icon color="text">mdi-exit-run</v-icon></v-btn>
    </v-card>
</template>

<script setup>
const props = defineProps(["game"]);

import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();

const GAME = gameStore.getGame(props.game);
const game = props.game;

import timeFormat from "@/timeFormat.mjs";

const ingame = (run) => {
    $socket.emit("ingame", { game, run });
};

const nextLang = () => {
    const languages = Object.keys(GAME.LANGUAGES);
    let i = languages.indexOf(GAME.LANG);
    i++;
    const lang = languages[i % languages.length];
    $socket.emit("language", { game: props.game, lang });
};

const get_toolbar_color = () => {
    let color = "#000";
    document.title = props.game;
    if (GAME.ON) color = "#008";
    if (GAME.HELPREQUEST === true) {
        color = "#F00";
        if (GAME.TIME % 2 === 0) {
            color = "#009";
            document.title = "█████████████████████";
        }
    }
    return color;
};
</script>

<style scoped>
.white {
    color: white;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
}
.time_display {
    font-size: 64px;
    font-family: var(--themefont), cursive;
    color: var(--v-text-base);
    /*background-color: rgba(0, 0, 0, 0.3);*/
}

.system-msg {
    /*background-color: rgba(0, 0, 0, 0.3);*/
    color: var(--v-text-base);
    padding: 5px;
    display: inline-block;
    height: 50px;
}
</style>
