<template>
    <v-list-item prepend-icon="mdi-palette" @click="theme_selection()" :title="theme.global.name.value"></v-list-item>
</template>

<script setup>
import { onMounted } from "vue";

import { useTheme } from "vuetify";
const theme = useTheme();

import { useSocketdataStore } from "@/store/socketdataStore.mjs";
const socket = useSocketdataStore();

let theme_selection = () => {};

onMounted(async () => {
    await $connection();
    await socket.initialization;

    if (socket.data.theme) {
        theme.global.name.value = socket.data.theme;
    }

    theme_selection = () => {
        $socket.emit("theme:selection", (t) => {
            theme.global.name.value = t;
        });
    };
});
</script>
