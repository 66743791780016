<template>
    <div class="pa-16">
        <vuetifind-dialog :format="data.format" :values="document" @update="update" @clickEnter="add" label="##&en Add new ##&hu Hozzáadása ##" />
        <selector-component :structure="data.structure" @leftClickElement="leftClickElement" />
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
import VuetifindDialog from "@/vuetifind-components/VuetifindDialog.vue";

const router = useRouter();

const document = reactive({});

const data = reactive({
    structure: {},
    format: {},
});

const leftClickElement = ({ uri }) => {
    router.push(uri);
};

const add = (e) => {
    $socket.emit("add-queries", document, ({ uri }) => {
        router.push(uri);
    });
};

const update = (a) => {
    Object.assign(document, a);
};

onMounted(async () => {
    await $connection();

    $socket.removeListener("update-queries");
    $socket.on("update-queries", (o) => {
        Object.assign(data, o);
    });

    $socket.emit("update-queries");
});
</script>
<script>
export default {
    path: "/queries",
    name: "##&en Queries ##&hu Lekérdezések ##",
    icon: "mdi-database-search",
    sort: 110,
    root: "Home",
    section: "queries",
};
</script>
