<template>
    <v-container>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent>
                <LoginCard @complete="completeHandler()" :autologin="true" />
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script setup>
import LoginCard from "@/LoginCard.vue";

import { ref, reactive, onMounted } from "vue";

import { useSessionStore } from "@/store/sessionStore.mjs";
const sessionStore = useSessionStore();

const dialog = ref(false);

const completeHandler = async () => {
    await sessionStore.fetchSession();
    dialog.value = false;
    //Ł("completeHandler");
};

onMounted(async () => {
    await sessionStore.initialization;
    const session = sessionStore.session;
    if (session.user) return;
    dialog.value = true;
});
</script>