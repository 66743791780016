<template>
    <div class="pa-16">
        <vuetifind-dialog :format="data.format" :values="document" @update="update" @clickEnter="add" label="##&en Add new ##&hu Hozzáadása ##" />
        <selector-component :structure="data.structure" @leftClickElement="leftClickElement" @rightClickElement="rightClickElement" />
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
import VuetifindDialog from "@/vuetifind-components/VuetifindDialog.vue";

const router = useRouter();

const document = reactive({});

const data = reactive({
    structure: {},
    format: {},
});

const leftClickElement = ({ database, collection, _id }) => {
    router.push("/gui/" + database + "/" + collection + "/" + _id);
};
const rightClickElement = ({ database, collection, _id }) => {
    window.open("/gui/" + database + "/" + collection + "/" + _id, "_blank");
};

const add = (e) => {
    $socket.emit("add-gui", document, ({ uri }) => {
        router.push(uri);
    });
};

const update = (a) => {
    Object.assign(document, a);
};

onMounted(async () => {
    await $connection();

    $socket.removeListener("update-gui");
    $socket.on("update-gui", (o) => {
        Object.assign(data, o);
    });

    $socket.emit("update-gui");
});
</script>
<script>
export default {
    path: "/gui",
    name: "##&en GUI ##&hu GUI ##",
    icon: "mdi-television-guide", //"mdi-monitor-dashboard",
    sort: 1110,
    root: "Home",
    section: "gui",
};
</script>
