<template>
    <v-container fluid class="pa-16">
        <v-toolbar>
            <v-btn icon size="large" @click.native="emitDocument('save')" :disabled="data.valid !== true">
                <v-icon>mdi-floppy</v-icon>
            </v-btn>
            <v-btn icon size="large" @click.native="emitDocument('find')" :disabled="data.valid !== true">
                <v-icon>mdi-database-search</v-icon>
            </v-btn>
            <v-toolbar-title>
                <pre>
 {{ route.params.database }} ##&en Query ##&hu Lekérdezés ## {{ data.query.database }} / {{ data.query.collection || "##&en Generic ##&hu Általános ##" }} / {{
                        data.query.name || data._id
                    }} </pre
                >
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon size="large" @click.native="emitDocument('trash')">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-toolbar>
        <v-text-field
            class="pa-6"
            v-model="data.query.name"
            label="* ##&en Name ##&hu Elnevezés ##"
            hint="##&en descriptive human-readable name ##&hu találóan körülíró elnevezés ##"
            prepend-icon="mdi-format-text"
        />
        <vuetiform v-model="data.query" v-model:valid="data.valid" :key="data.key" />
    </v-container>
    DOCUMENT
    <pre>{{ data.query }}</pre>
    FORMAT
    <pre>{{ data.format }}</pre>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import Vuetiform from "@/vuetiform-components/QueriesForm.vue";

const router = useRouter();
const route = useRoute();

import { useQueryStore } from "@/store/queryStore.mjs";
const store = useQueryStore();

const data = reactive({
    database: "",
    collection: "",
    _id: route.params.id,
    format: {},
    query: {},
    initialValues: {},
    key: 0,
});

const emitDocument = (act) => {
    $socket.emit(
        "query-document-" + act,
        {
            database: route.params.database,
            _id: route.params.id,
            query: data.query,
        },
        (o) => {
            if (o.uri) return router.push(o.uri);
            Object.assign(data, o);
            store.assign(o);
            data.key++;
        }
    );
};

const update = () => {
    $socket.emit(
        "update-query-document",
        {
            database: route.params.database,
            _id: route.params.id,
        },
        (o) => {
            Object.assign(data, o);
            store.assign(o);
            data.key++;
        }
    );
};

onMounted(async () => {
    //Ł("onMounted", route.params.id);
    await $connection();
    update();
});
</script>
<script>
export default {
    path: "/queries/:database/queries/:id",
    name: "queries-editor-document",
    icon: "mdi-cog-box",
    sort: 110,
    root: "Home",
};
</script>
