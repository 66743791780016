<template>
    <v-card class="transparent white center">
        <v-btn class="ma-2" fab color="primary" @click="send_text()" title="Send Text"><v-icon color="text">mdi-send-circle</v-icon></v-btn>
        <v-text-field v-model="data.text" append-icon="mdi-close-octagon" @click:append="data.text = ''" @keyup.enter="send_text()" />
    </v-card>
    <v-row justify="center">
        <v-card
            class="ma-10 pa-2 white"
            min-width="300"
            max-width="300"
            min-height="50"
            max-height="50"
            color="primary"
            @click="
                data.dialog = true;
                data.sectioner = true;
            "
        >
            <v-toolbar-title class="center">
                {{ GAME.SECTION || "Section" }}
            </v-toolbar-title>
        </v-card>
        <v-dialog v-model="data.dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
            <v-card class="dark-transparent">
                <v-toolbar dark color="primary" @click="data.dialog = false">
                    <v-spacer></v-spacer>
                    <v-btn class="ma-2" color="white" @click.stop="ingame('previous_section')" title="previous section"><v-icon>mdi-chevron-left-box</v-icon></v-btn>
                    <v-toolbar-title class="center">
                        <v-btn class="ma-2" width="2000" color="white" @click.stop="data.sectioner = !data.sectioner">{{ GAME.SECTION || "Section" }}</v-btn>
                    </v-toolbar-title>
                    <v-btn class="ma-2" color="white" @click.stop="ingame('next_section')" title="next section"><v-icon>mdi-chevron-right-box</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-10" icon dark>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-container id="section-selection" v-if="data.sectioner" fluid>
                    <v-card
                        :title="section"
                        @click="setSection(section)"
                        fluid
                        v-for="section in GAME.SECTIONS"
                        class="ma-2 pa-2 white"
                        :color="GAME.SECTION === section ? '#800' : '#008'"
                    >
                    </v-card>
                </v-container>

                <v-container id="object-selection" v-if="!data.sectioner" fluid>
                    <v-card class="ma-2 pa-2 white" :color="getCardColor(document)" v-for="document in getGameObjects()">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <v-avatar class="ma-3" size="200" rounded="0">
                                <v-img v-if="document.thumb" class="white--text align-end" height="200px" :src="getTumbnail(document)" />
                            </v-avatar>
                            <v-card-text @click="enqueue(document)" class="cardtop">
                                <p class="entitle">{{ document.name }}</p>
                                <v-card-subtitle class="subtitle ma-2 pa-2">{{ document["subtitle_" + GAME.LANG] }}</v-card-subtitle>
                            </v-card-text>
                        </div>
                        <v-card-actions>
                            <v-btn v-if="document.lastcall" color="orange-lighten-2" variant="text" :key="data.key"> {{ timeFormatSince(document.lastcall) }} </v-btn>
                            <v-btn v-if="document.count > 0" color="orange-lighten-1" variant="text"> {{ document.count }}x </v-btn>

                            <v-btn v-if="document.trigger" color="blue-lighten-2" variant="text" class="noup"> [{{ document.trigger }}] </v-btn>
                            <v-btn v-if="document.time" color="orange-lighten-2" variant="text"> @ {{ document.time }} </v-btn>

                            <v-btn v-if="document.midinote" color="blue-lighten-2" icon="mdi-music-note"></v-btn>

                            <v-btn v-if="document['media_' + GAME.LANG]" color="blue-lighten-4" icon="mdi-image-outline"></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="red-lighten-1" variant="text"> {{ queued(document) }} </v-btn>
                            <v-btn color="red-lighten-1" variant="text"> {{ blocks(document) }} </v-btn>
                            <v-btn icon="mdi-close" @click="remove(document)"></v-btn>
                            <v-btn icon="mdi-file-document-edit-outline" @click="open(document)"></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import timeFormat from "@/timeFormat.mjs";

const props = defineProps(["game"]);
const filters = {};

filters.section = (e) => {
    return e.section === GAME.SECTION;
};

filters.timed = (e) => {
    return e.at !== undefined;
};
filters.midi = (e) => {
    return e.midinote !== undefined;
};
filters.keypress = (e) => {
    return e.trigger !== undefined;
};

const data = reactive({
    filters: ["section"],
    text: "",
    key: 0,
    dialog: false,
    sectioner: false,
});

setInterval(() => {
    data.key++;
}, 1000);

import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();

const GAME = gameStore.getGame(props.game);
const game = props.game;
const ingame = (run) => {
    $socket.emit("ingame", { game, run });
};

const send_text = () => {
    $socket.emit("ingame", { game: props.game, run: "send_text", text: data.text });
};
const enqueue = (object) => {
    data.dialog = false;
    $socket.emit("ingame", { game: props.game, run: "enqueue", object });
};
const remove = (object) => {
    $socket.emit("ingame", { game: props.game, run: "remove", object });
};
const setSection = (section) => {
    data.sectioner = false;
    $socket.emit("ingame", { game: props.game, run: "set_section", section });
};
const open = (document) => {
    window.open("https://neverland.nete.hu/document/neverland_database/" + props.game + "/" + document._id, "_blank");
};
const getTumbnail = (document) => {
    return "/document/neverland_database/" + props.game + "/" + document._id + "/" + document.thumb;
};
const getGameObjects = () => {
    if (!GAME.OBJECTS) return [];
    let arr = [...GAME.OBJECTS];
    for (const f of data.filters) arr = arr.filter(filters[f]);
    return arr;
};

const queued = (document) => {
    const ids = GAME.QUEUES[document.queue].map((e) => e._id);
    if (ids.includes(document._id)) return "Q" + (1 + ids.indexOf(document._id)) + ".";
    return "-";
};
const blocks = (document) => {
    if (!GAME.OBJECT._id !== document._id) return "-";
    return timeFormat(GAME.BLOCKS[document.queue]);
};

const getCardColor = (d) => {
    if (GAME.OBJECT) if (GAME.OBJECT._id === d._id) return "#008";
    if (d.count > 0) {
        if (d.once) return "#000";
        return "#111";
    }
    return "#333";
};

const timeFormatSince = (timeSince) => {
    console.log("timeSince: " + timeSince);
    if (!timeSince) return;
    const since = Math.ceil((new Date().getTime() - new Date(timeSince).getTime()) / 1000);
    if (since >= 60) return "~" + Math.ceil(since / 60) + "m";
    else return "~" + since + "s";
};
</script>

<style scoped>
.transparent {
    background: #0008;
}
.dark-transparent {
    background: #000d;
}
.white {
    color: white;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
}
.cardtop {
    overflow: hidden;
    background: #0005;
}
.cardtop:hover {
    cursor: pointer;
}
.noup {
    text-transform: none !important;
}
.v-btn {
    text-transform: none;
}
.large-btn {
    font-size: 24px;
    padding: 12px 24px;
}
.entitle {
    font-size: 30px;
    line-height: 1.2;
}
#section-selection {
    max-width: 800px;
}
#object-selection {
}
</style>
