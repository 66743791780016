<template>
    <v-card class="ma-3">
        <v-toolbar density="compact">
            <v-btn icon @click="add()">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn icon @click="remove()">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn icon @click="moveUp()">
                <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn icon @click="moveDown()">
                <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
            <v-btn icon @click="data.panel = -1">
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <pre>{{ props.label }}</pre>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-expansion-panels v-model="data.panel" :key="data.key">
            <v-expansion-panel v-for="(x, i) in data.values">
                <v-expansion-panel-title>
                    <div class="font-weight-bold">
                        <pre>#{{ i + 1 }} {{ x.name }} </pre>
                    </div>
                    <template v-if="false" v-slot:actions>
                        {{ data.valids[i] }}
                        <v-icon color="error" icon="mdi-alert-circle"> </v-icon>
                    </template>
                </v-expansion-panel-title>
                <template v-slot:text>
                    <vuetiform-poliform
                        :bond="props.bond"
                        :format="props.format"
                        v-model="data.values[i]"
                        v-model:valid="data.valids[i]"
                        @update="updateHandler()"
                        :identifier="props.identifier"
                        :index="i"
                        :disabled="props.disabled"
                    />
                </template>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script setup>
// sortable array of formatted objects

// SYMLINKED !!!

import VuetiformPoliform from "@/vuetiform/VuetiformPoliform.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
function clone(p) {
    return structuredClone(toRaw(p));
}

// the identifier helps to find the array index in subsequent leaf components.
const props = defineProps(["bond", "format", "modelValue", "disabled", "initialValues", "label", "identifier"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ values: clone(props.modelValue || []), panel: -1, key: 0, valids: [] });

const add = () => {
    data.values.push(clone(props.initialValues || {}));
    data.valids.push(true); // TODO
    data.key++;
    updateHandler();
};
const remove = () => {
    const i = data.panel;
    data.values.splice(i, 1);
    data.valids.splice(i, 1);
    data.key++;
    data.panel = -1;
    updateHandler();
};

const moveUp = () => {
    const i = data.panel;
    if (!arrayMove(data.values, i, i - 1)) return;
    arrayMove(data.valids, i, i - 1);
    data.panel = i - 1;
    updateHandler();
};
const moveDown = () => {
    const i = data.panel;
    if (!arrayMove(data.values, i, i + 1)) return;
    arrayMove(data.valids, i, i + 1);
    data.panel = i + 1;
    updateHandler();
};
const isValid = () => {
    for (const x of data.valids) if (x !== true) return x;
    return true;
};
const updateHandler = async () => {
    await nextTick();
    const datum = clone(data.values);
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};

function arrayMove(arr, fromIndex, toIndex) {
    if (fromIndex < 0) return false;
    if (fromIndex > arr.length - 1) return false;
    if (toIndex < 0) return false;
    if (toIndex > arr.length - 1) return false;
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return true;
}
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-flexiform",
};
</script>
