<template>
    <OperatorClock :game="route.params.game" />
</template>

<script setup>
// Default for a generic game

import { ref, onMounted, reactive, computed, watch } from "vue";
import OperatorClock from "@/OperatorClock.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();

const game = route.params.game;
const ingame = (run) => {
    $socket.emit("ingame", { game, run });
};

onMounted(async () => {
    $socket.connect();
    await $socket.connection;

    ingame("update");

    $socket.emit("route-change", route.fullPath);

    $socket.on("redirect", (to) => {
        return router.push(to);
    });

    router.afterEach((to, from) => {
        $socket.emit("route-change", to.fullPath);
    });
});
</script>

<script>
export default {
    path: "/operator/:game",
    name: "Operator",
    icon: "mdi-piggy-bank",
    //root: "Home",
    //section: "neverland",
};
</script>
