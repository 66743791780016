const options = { weekday: "long", year: "numeric", month: "long", day: "2-digit", hourCycle: "h24" };

export default function getFieldValue(format, document, key) {
    if (format[key]) {
        if (format[key].items) {
            const value = document[key];
            const items = format[key].items.filter((o) => {
                if (o === null) return false;
                return o.value === value;
            });

            if (items.length === 1) return items[0].title;

            if (Array.isArray(value)) {
                const o = format[key].items.reduce((obj, item) => {
                    if (typeof item !== "object") item = { title: item, value: item };
                    obj[item.value] = item.title;
                    return obj;
                }, {});
                return value.map((e) => o[e]).join(", ");
            }
        }
        if (format[key]._type === "Date") {
            const date = new Date(document[key]);
            return date.toLocaleTimeString("##&en en ##&hu hu ##", options);
        }
    }
    if (document[key] !== undefined) return document[key];

    if (key === "name") {
        const timestamp = document._id.substring(0, 8);
        const date = new Date(parseInt(timestamp, 16) * 1000);
        return date.toLocaleTimeString("##&en en ##&hu hu ##", options);
    }

    return "-";
}
