<template>
    <div v-for="(definition, key, i) in props.format">
        <vuetiform-component :key="i" :format="definition" v-model="data[key]" v-model:valid="valid[key]" @update="updateHandler(key)" :disabled="props.disabled" />
    </div>
</template>

<script setup>
// form that can change shape depending on a value

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["format", "modelValue", "disabled"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive(clone(props.modelValue || {}));

// validators
const valid = reactive({});
const isValid = () => {
    for (const i in valid) if (valid[i] !== true) return i + ": " + valid[i];
    return true;
};

const updateHandler = async (from) => {
    await nextTick();
    const datum = clone(data);
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};
//updateHandler();
emit("update:valid", isValid());

onMounted(async () => {
    // maybe
    emit("update:valid", isValid());
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-object",
};
</script>
