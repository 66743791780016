/* global $socket:false, $connection:false */

import { defineStore } from "pinia";
import { ref } from "vue";

export const useSocketdataStore = defineStore("socket-data", () => {
    const data = ref({});
    const connected = ref(false);
    const disconnected = ref(false);
    const handlers = [];

    const initialization = new Promise((resolve, reject) => {
        async function getData() {
            await $connection();

            $socket.emit("get-socket-data", (e) => {
                //console.log("get-socket-data");
                data.value = e;
                for (const handle of handlers) handle("get-socket-data", e);
                resolve();
            });

            // server-side: socket.emit("set-socket-data", socket.data);
            $socket.on("set-socket-data", (e) => {
                //console.log("set-socket-data");
                data.value = e;
                for (const handle of handlers) handle("set-socket-data", e);
            });

            // server-side: socket.emit("update-socket-data", { });
            $socket.on("update-socket-data", (e) => {
                Object.assign(data.value, e);
                for (const handle of handlers) handle("update-socket-data", e);
            });

            $socket.on("connect", () => {
                //console.log("socket.connected"); // true
                connected.value = true;
                disconnected.value = false;
                for (const handle of handlers) handle("socket-connected");
            });

            $socket.on("disconnect", () => {
                //console.log("socket.disconnected"); // false
                connected.value = false;
                disconnected.value = true;
                for (const handle of handlers) handle("socket-disconnected");
            });

            $socket.on("logout", () => {
                //console.log("socket.disconnected"); // false
                connected.value = false;
                //disconnected.value = true;
                for (const handle of handlers) handle("socket-logout");
            });
            //
            connected.value = true;
        }

        getData();
    });

    return { data, handlers, connected, disconnected, initialization };
});

/************ usage: ****************\

import { ref, onMounted, reactive } from "vue";

import { useSocketdataStore } from "@/store/socketdataStore.mjs";
const socket = useSocketdataStore();
  
onMounted(async () => {
  	await socket.initialization;
    console.log(socket.data)
});

\************************************/
