<template>
    <v-list-item v-for="v in getNavigation()" :prepend-icon="v.icon" :title="getTitle(v)" @click="router.push(v.link)"></v-list-item>
</template>

<script setup>
import { onMounted } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { useSocketdataStore } from "@/store/socketdataStore.mjs";
const socket = useSocketdataStore();

const getNavigation = () => {
    const nav = socket.data.navigation || {};

    const routes = router
        .getRoutes()
        .map((e) => e.meta)
        .filter((e) => nav[e.section] || e.section === true)
        .sort((a, b) => a.sort - b.sort);

    return routes;
};

const getTitle = (v) => {
    if (v.name[0] === "$") if (v.name === "$getName") return getName();
    return v.name;
};

const getName = () => {
    if (!socket) return "";
    if (!socket.data) return "";
    if (!socket.data.user) return "";
    if (!socket.data.user.name) return "";
    return socket.data.user.name;
};

onMounted(async () => {
    await $connection();
    await socket.initialization;
});
</script>
