<template>
    <document-view :database="database" :collection="collection" :id="id" :redirecting="true" :key="id" />
</template>

<script setup>
import DocumentView from "@/database-components/DocumentView.vue";

import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const database = route.params.database;
const collection = route.params.collection;
const id = route.params.id;
</script>

<script>
export default {
    path: "/document/:database/:collection/:id",
    name: "database-document",
    icon: "mdi-database",
    sort: 100,
    root: "Home",
};
</script>

<style scoped></style>
