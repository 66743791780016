<template>
    <div id="operator" class="fill-height">
        <OperatorClock :game="game" />
        <v-btn block @click="ingame('helprequest')" id="helpRequestButton">Help</v-btn>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

const game = "serialkiller";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { useGameStore } from "@/store/gameStore.mjs";
const gameStore = useGameStore();
const GAME = gameStore.getGame(game);

import OperatorClock from "@/OperatorClock.vue";
const ingame = (run) => {
    $socket.emit("ingame", { game, run });
};

onMounted(async () => {
    $socket.connect();
    await $socket.connection;
    gameStore.getUpdate(game);
});
</script>

<script>
export default {
    path: "/kiosk/serialkiller/none",
    name: "Kiosk",
    icon: "mdi-piggy-bank",
};
</script>
<style scoped>
@font-face {
    font-family: "serialkiller-primary";
    src: url("@/serialkiller-font/D250_junker-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
#operator {
    background: url("@/serialkiller-media/background.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top: 70px;
}
#textBlock {
    min-height: 300px;
    padding: 10px;
    background: #0008;
    text-align: center;
    margin-left: 5vw;
    width: 90vw;
}
.textContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    line-height: 0.8;
    word-break: break-word;
}

.textEffect {
    color: #ffffff;
    font-family: "serialkiller-primary", monospace;
    font-size: 48px;
}

#flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 100vh;
}
</style>
